import React, { Fragment } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import Checkbox from "./Checkbox";
import ModalAddress from "./ModalAddress";
import ModalObs from "./ModalObs";
import OrderCard from "../components/NewOrderDataCard";
import getProductByID from "../helpers/getProductByID";
import { InputMoney, InputContainer } from "./InputText";

import { StockContext } from "../hooks/useStock";

import Axios from "../Axios";
import ModalConfirmedOrder from "./ModalConfirmedOrder";
import ModalCreditCards from "./ModalCreditCards";

const Block = styled.div`
  width: 100%;
  height: calc(100% - 104px);
  background-color: #fff;
  margin: 0;
  padding: 8px 24px 24px;
  overflow: auto;
`;

const Resume = styled.div`
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;

  h3 {
    color: #262524;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 16px;
  }

  h4 {
    color: #000000;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  &:after {
    content: "";
    display: block;
    background-color: #d8d8d8;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
  }
`;

const Delivery = styled.div`
  padding: 24px 0;
  position: relative;

  h3 {
    color: #262524;
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 10px;
  }

  button {
    border: none;
    background: transparent;
    color: #d18b00;
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    cursor: pointer;
    outline: 0;
    padding: 0;

    &:hover {
      opacity: 0.7;
      transition: 0.3s all;
    }
  }

  &:after {
    content: "";
    display: block;
    background-color: #d8d8d8;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
  }
`;

const Payment = styled.div`
  padding: 16px 0;
  position: relative;

  h3 {
    color: #262524;
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .checkbox__container {
    padding: 12px 0;

    label {
      color: #262524;
      font-family: ArialMT;
      font-size: 16px;
      font-weight: 400;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &:after {
    content: "";
    display: block;
    background-color: #d8d8d8;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
  }
`;

const Discount = styled.div`
  padding: 16px 0;

  h3 {
    color: #262524;
    font-family: "ArialMT";
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .discount-container {
    position: relative;
    display: flex;
    align-items: center;

    input {
      padding-right: 80px;
    }

    .discount-apply {
      position: absolute;
      right: 10px;
      color: #d18b00;
      font-family: "ArialMT";
      font-size: 12px;
      padding: 0;
      border: 0;
      background: transparent;
      cursor: pointer;
    }
  }

  .student {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .switch {
    display: none;

    &__container {
      width: 63px;
    }

    & + label {
      border-radius: 10px;
      cursor: pointer;
      display: block;
      height: 23px;
      outline: none;
      user-select: none;
      position: relative;
      width: 63px;
      color: #000;
      font-size: 8px;
      font-family: "ArialMT";
      display: flex;
      align-items: center;
      padding: 0 16px;
      box-sizing: border-box;
      justify-content: flex-end;
      z-index: 0;

      &:before,
      &:after {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        content: "";
        z-index: -1;
      }

      &:before {
        right: 0px;
        background-color: #e6e6e6;
        border-radius: 20px;
        transition: background 0.4s;
      }

      &:after {
        left: -3px;
        top: 0px;
        width: 23px;
        height: 23px;
        background-color: #525252;
        border-radius: 100%;
        transition: all 0.4s;
      }
    }

    &:checked + label {
      justify-content: flex-start;
      &:after {
        transform: translateX(44px);
        background-color: #d18b00;
      }
    }
  }

  p {
    color: #262524;
    font-family: "ArialMT";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
  }
`;

const Total = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 24px;
  flex-direction: column;

  p.total {
    color: #414141;
    font-family: Arial;
    font-size: 18px;
    font-weight: 700;
    line-height: 19px;
  }

  p.troco {
    margin-top: 8px;
    color: #414141;
    font-family: "ArialMT";
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
`;

const CashPayment = styled.div`
  display: flex;
  align-items: center;
  padding-left: 32px;

  p {
    color: #838383;
    font-family: "ArialMT";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding-right: 10px;
  }

  .input__container {
    position: relative;
  }

  span {
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 8px;
    color: #838383;
    font-family: "ArialMT";
    font-size: 14px;
  }

  button {
    background-color: #d8d8d8;
    border: 1px solid #979797;
    width: 34px;
    height: 30px;
    color: #838383;
    font-family: "ArialMT";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }

  input {
    width: 105px;
    padding-left: 30px;
    border: 1px solid #979797;
    height: 30px;
    position: relative;
    top: 1px;
    outline: 0;
    color: #838383;

    &:before {
      content: "R$";
    }
  }
`;

let AddressCard = styled.div`
  margin-bottom: 15px;

  p {
    color: #414141;
    font-family: Arial;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 3px;

    strong {
      font-weight: 700;
    }
  }
`;

const P = styled.p`
  color: #414141;
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 3px;
`;

class NewOrderData extends React.Component {
  state = {
    shouldRenderModalAddress: false,
    shouldRenderConfirmed: false,
    shouldRenderModalObs: false,
    shouldRenderModalCreditCards: false,
    obs: null,
    cashPayment: false,
    creditCard: false,
    isCreditCard: false,
    inventory: null,
    price: 0,
    address: null,
    confirmDisabled: true,
    confirmID: null,
    confirmPDF: "",
    exchange: "0,00",
    troco: null,
    studentDiscountPrice: 0,
    discount: 0,
    observations: ""
  };

  openModalAddress() {
    this.setState({ shouldRenderModalAddress: true });
  }

  openModalObs(id) {
    this.setState({ shouldRenderModalObs: true, obs: id });
  }

  closeModalObs() {
    this.setState({ shouldRenderModalObs: false, obs: null });

    this.props.updateResume();
  }

  closeModalCreditCards() {
    this.setState({ shouldRenderModalCreditCards: false }, () => {
      this.checkConfirmStatus();
    });
  }

  openModalCreditCards() {
    this.setState({ shouldRenderModalCreditCards: true });
  }

  closeConfirm() {
    this.setState({
      shouldRenderConfirmed: false,
      confirmID: null,
      confirmPDF: null,
    });
  }

  closeModalAddress() {
    this.setState(
      {
        shouldRenderModalAddress: false,
        address: JSON.parse(localStorage.getItem("address")),
      },
      () => {
        this.checkConfirmStatus();
      }
    );
  }

  activeCashPayment() {
    if (!this.state.cashPayment) {
      this.setState(
        { cashPayment: true, isCreditCard: false, creditCard: "" },
        () => {
          this.checkConfirmStatus();
        }
      );
      this.props.onChangePayment("cash");
      return;
    }

    this.setState({ cashPayment: false });
  }

  activeCreditCard() {
    if (this.state.isCreditCard) {
      this.openModalCreditCards();
      return;
    }

    this.setState(
      { cashPayment: false, isCreditCard: true, creditCard: "" },
      () => {
        this.checkConfirmStatus();
        this.openModalCreditCards();
      }
    );

    this.props.onChangePayment("credit");
  }

  onChangeCreditCard(value) {
    this.setState({ creditCard: value });
  }

  activeSplit() {
    this.setState({ cashPayment: false, isCreditCard: false, creditCard: "" });
  }

  calcStudentDiscount(discount = 5) {
    return this.state.price.price - (this.state.price.price * discount) / 100;
  }

  setStudentDiscout(flag) {
    this.setState(() => ({
      studentDiscountPrice:
        this.props.studentDiscount && flag ? 0 : this.calcStudentDiscount(5),
    }));
  }

  activeStudentDiscount() {
    this.props.onChangeStudentDiscout();
    this.setStudentDiscout(true);
    this.calcExchange();
  }

  checkConfirmStatus() {
    this.setState({ confirmDisabled: true });

    if (!this.state.inventory || this.state.inventory.length === 0) {
      return false;
    }

    if (
      this.state.cashPayment === false &&
      this.state.isCreditCard === false &&
      !this.props.payment
    ) {
      return false;
    }

    this.setState({ confirmDisabled: false });
  }

  async getInventory() {
    return await Axios.get("/inventory").then((response) => {
      this.setState(
        {
          inventory: response.data.inventory,
          price: response.data.price,
          address: JSON.parse(localStorage.getItem("address")),
        },
        () => {
          this.setStudentDiscout(false);
          this.checkConfirmStatus();
        }
      );

      localStorage.setItem(
        "inventory",
        JSON.stringify(response.data.inventory)
      );
      this.checkConfirmStatus();
      this.calcExchange();
    });
  }

  async save() {
    if (this.props.isEditingOrder) {
      const body = { products: this.state.inventory };
      await Axios.put(`/orders/${this.props.editingOrderId}/update`, body).then(
        (req) => {
          this.setState(
            {
              cashPayment: false,
              isCreditCard: false,
              creditCard: "",
              inventory: [],
              confirmDisabled: true,
              price: 0,
              address: null,
              shouldRenderModalAddress: false,
              confirmID: req.data.id,
              confirmPDF: req.data.pdf,
            },
            () => {
              if (document.querySelector('input[name="payment-type"]:checked'))
                document.querySelector(
                  'input[name="payment-type"]:checked'
                ).checked = false;
            }
          );
          localStorage.removeItem("address");
        }
      );
      await Axios.delete("/inventory");
      window.location.href = "/";
      return;
    }

    let data = {
      isDelivery: true,
      paymentMode: this.state.cashPayment ? "cash" : "physical_card",
      deliveryTime: document
        .querySelector("#inputTimer")
        .getAttribute("data-time"),
      created_for: this.props.user.id,
      name: sessionStorage.getItem("userNameInput"),
      cpfCnpj: sessionStorage.getItem("cpfCnpj")
        ? sessionStorage.getItem("cpfCnpj").replace(/[^0-9]/g, "")
        : undefined,
      observations: this.state.observations,
      change: this.state.exchange !== "0,00" ? this.state.exchange : 0,
      isStudent: this.props.studentDiscount,
      // statusPayment: false
      // discountValue: String(this.state.discount)
    };

    if (this.state.isCreditCard) data.flag = this.state.creditCard;
    if (this.state.address_id) data.address_id = this.state.address.id;

    Axios.post("/submit-order", data)
      .then((req) => {
        console.log("PDF > " + req.data.pdf);
        this.setState(
          {
            cashPayment: false,
            isCreditCard: false,
            creditCard: "",
            inventory: [],
            confirmDisabled: true,
            price: 0,
            address: null,
            shouldRenderConfirmed: true,
            shouldRenderModalAddress: false,
            confirmID: req.data.id,
            confirmPDF: req.data.pdf,
          },
          () => {
            if (document.querySelector('input[name="payment-type"]:checked'))
              document.querySelector(
                'input[name="payment-type"]:checked'
              ).checked = false;
          }
        );

        localStorage.removeItem("address");
        Axios.delete("/inventory");
        this.props.clearInventory();
        this.setState({ observations: "" });
        this.setState({ userNameInput: "" });
        this.setState({ cpfCnpj: "" });
        sessionStorage.removeItem("userNameInput");
        sessionStorage.removeItem("cpfCnpj");
        sessionStorage.removeItem("observations");
      })
      .catch((error) => {
        let message = "Houve um erro ao salvar o pedido, tente novamente...";

        if (
          error.response.data === "Um ou mais campos são inválidos." &&
          error.response.status === 401
        ) {
          message =
            "Por favor, insira o nome do cliente para finalizar o pedido.";
        }

        if (
          error.response.data.error ===
          "Não há pão suficiente para realizar a venda."
        ) {
          message =
            "Não há quantidade de pães suficiente para realizar este pedido.";
        }

        alert(message);
      });
    this.context.setIsOrder(!this.context.isOrder);
  }

  async getAddress() {
    await Axios.get(`/addresses/${parseInt(this.props.user.address_id)}`).then(
      (res) => {
        let obj = res.data;
        window.localStorage.setItem("address", JSON.stringify(obj));
        this.setState({ address: obj, address_id: obj.id });
      }
    );
  }

  componentDidMount() {
    if (this.props.editSavedOrder) {
      this.setState({ inventory: [this.props.inventory] });
      return;
    }
    // if (this.props.user && this.props.user.address_id) {
    //   this.getAddress();
    // }

    if (this.props.payment === "cash") {
      this.setState({ cashPayment: true, isCreditCard: false }, () => {
        this.checkConfirmStatus();
        this.calcExchange();
      });
    } else {
      this.setState({ cashPayment: false, isCreditCard: false }, () => {
        this.checkConfirmStatus();
        this.calcExchange();
      });
    }

    this.setState({
      studentDiscount: this.props.studentDiscount,
    });

    this.getInventory();
    // this.calcExchange();
  }

  async calcExchange() {
    if (!document.querySelector("#trocoCalc")) return;

    let exchangeFor = document.querySelector("#trocoCalc");

    if (exchangeFor)
      if (!exchangeFor || exchangeFor.value === "") {
        this.setState({ exchange: "0,00" });
        return;
      }

    exchangeFor = exchangeFor.value;

    let exchange;

    await new Promise((r) => setTimeout(r, 200));

    if (this.props.studentDiscount) {
      exchange =
        parseFloat(exchangeFor) - parseFloat(this.state.studentDiscountPrice);
    } else {
      exchange = parseFloat(exchangeFor) - parseFloat(this.state.price.price);
    }

    if (exchange < 0) {
      this.setState({ exchange: "0,00" });
      return;
    }

    this.setState({ exchange: parseFloat(exchange).toFixed(2) });
  }

  async onCondimentsUpdate(product, list) {
    let removed = product.extra.exclude_ingredients
        ? product.extra.exclude_ingredients.map((removed) => removed.id)
        : [],
      extra = product.extra.add_extra_ingredients
        ? product.extra.add_extra_ingredients.map((extra) => {
            return {
              ingredient: extra.ingredient.id,
              quantity: extra.quantity,
            };
          })
        : [],
      spices = [],
      data = {
        product_id: product.id,
        observations: product.observations,
        exclude_ingredients: removed,
        add_extra_ingredients: extra,
        quantity: 1,
        spices,
      };

    let condiments = list.querySelectorAll(".condiment-input");
    for (let i = 0; i < condiments.length; i++) {
      let c = condiments[i];
      let q = parseInt(c.value);

      if (q)
        data.spices.push({
          spice: parseInt(c.name),
          quantity: parseInt(c.value),
        });
    }

    Axios.put(`/inventory/items/update/${product.inventory_ref}`, data).then(
      (res) => {
        alert("Condimentos Atualizados");
        this.setState({ inventory: [] }, () => {
          this.getInventory();
        });
      }
    );
  }

  getProductPrice(item) {
    if (item.add_extra_ingredients.length === 0) {
      return item.price
        ? `R$ ${parseFloat(Number(item.product.price))
            .toFixed(2)
            .replace(".", ",")}`
        : ``;
    }

    const priceExtraIngredients = item.add_extra_ingredients.reduce(
      (acc, ingredient) =>
        acc + Number(ingredient.ingredient.price) * ingredient.quantity,
      0
    );

    return item.price
      ? `R$ ${parseFloat(Number(item.product.price) + priceExtraIngredients)
          .toFixed(2)
          .replace(".", ",")}`
      : ``;
  }

  render() {
    return (
      <Block>
        <Resume>
          <h3>Resumo do pedido</h3>
          {this.state.inventory && this.state.inventory.length > 0 ? (
            this.state.inventory.map((item) => (
              <OrderCard
                onCondimentsUpdate={this.onCondimentsUpdate.bind(this)}
                ingredients={
                  getProductByID(
                    JSON.parse(window.localStorage.getItem("products")),
                    item.product.id
                  )
                    ? getProductByID(
                        JSON.parse(window.localStorage.getItem("products")),
                        item.product.id
                      ).ingredients
                    : []
                }
                onClickOpenObs={this.openModalObs.bind(this)}
                onClickCloseObs={this.closeModalAddress.bind(this)}
                id={item.product.id}
                extra={{
                  add_extra_ingredients: item.add_extra_ingredients,
                  exclude_ingredients: item.exclude_ingredients,
                }}
                spices={item.spices}
                inventory_ref={item.ref}
                observations={item.observations}
                cpfCnpj={item.cpfCnpj}
                key={item.ref}
                name={item.product.name}
                price={this.getProductPrice(item)}
                category={item.product.category_id}
                onClickRemoveOrder={this.props.onClickRemoveOrder}
                onDuplicateOrder={this.props.onDuplicateOrder}
                onClickEditOrder={this.props.onClickEditOrder}
              ></OrderCard>
            ))
          ) : (
            <h4>Nenhum item adicionado ainda</h4>
          )}
        </Resume>
        <Delivery>
          <h3>Entrega</h3>
          {
            this.state.address ? (
              <Fragment>
                <AddressCard>
                  <p>
                    <strong>{this.state.address.name}</strong> -{" "}
                    {this.state.address.streetName}
                  </p>
                  <p>
                    {this.state.address.streetNumber} -{" "}
                    {this.state.address.complement}{" "}
                  </p>
                  <p>{this.state.address.cep}</p>
                </AddressCard>
                <button onClick={this.openModalAddress.bind(this)}>
                  Alterar Endereço de Entrega
                </button>
              </Fragment>
            ) : (
              <Fragment>
                <P>Nenhum endereço cadastrado</P>
              </Fragment>
            )
            // : <button onClick={this.openModalAddress.bind(this)}>Adicionar Endereço de Entrega</button>
          }
        </Delivery>
        <Payment>
          <h3>Pagamento</h3>
          <Checkbox
            defaultChecked={this.props.payment === "cash" ? true : false}
            onClick={this.activeCashPayment.bind(this)}
            id="cash"
            name="Pagamento em dinheiro"
            type="radio"
            inputName="payment-type"
          ></Checkbox>
          {this.state.cashPayment ? (
            <CashPayment>
              <p>Troco para:</p>
              <div className="input__container">
                <span>R$</span>
                <input
                  defaultValue={this.props.exchange ? this.props.exchange : ""}
                  id="trocoCalc"
                  onChange={
                    ((e) => this.props.onChangeExchange(e),
                    this.calcExchange.bind(this))
                  }
                ></input>
              </div>
            </CashPayment>
          ) : (
            ``
          )}
          <Checkbox
            defaultChecked={this.props.payment === "credit" ? true : false}
            onClick={this.activeCreditCard.bind(this)}
            id="card"
            name={
              this.state.isCreditCard
                ? `Máquina de cartão (${this.state.creditCard})`
                : `Máquina de cartão`
            }
            type="radio"
            inputName="payment-type"
          ></Checkbox>
          {/* <Checkbox onClick={this.activeSplit.bind(this)} id="split" name="Dividir a conta" type="radio" inputName="payment-type"></Checkbox> */}
        </Payment>
        <Discount>
          <h3>Desconto</h3>

          <InputContainer className="discount-container">
            <InputMoney
              ref={(r) => (this.discount = r)}
              precision={2}
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator=""
              name="discount"
              placeholder="R$ 0,00"
              defaultValue={this.props.discount}
              value={this.state.discount}
              onChangeEvent={(e, val) => {
                let discount = this.discount.state.value;
                this.setState({ discount });
              }}
            ></InputMoney>
          </InputContainer>
          <div className="student">
            <p>Desconto estudante</p>
            <div className="switch__container">
              <input
                id="switch-shadow"
                defaultChecked={this.props.studentDiscount}
                checked={this.props.studentDiscount}
                onChange={this.activeStudentDiscount.bind(this)}
                className="switch switch--shadow"
                type="checkbox"
              />
              <label htmlFor="switch-shadow">
                {this.props.studentDiscount ? "Sim" : "Não"}
              </label>
            </div>
          </div>
        </Discount>

        <Total>
          <p className="total">
            Total:{" "}
            {(this.props.studentDiscount || this.state.discount !== 0) &&
            this.state.price.price !== 0 ? (
              <del style={{ color: "#838383" }}>
                R${" "}
                <span>
                  {parseFloat(this.state.price.price)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")}
                </span>{" "}
              </del>
            ) : (
              ``
            )}
            R${" "}
            <span>
              {parseFloat(
                this.props.studentDiscount
                  ? this.state.studentDiscountPrice - this.state.discount
                  : this.state.price.price - this.state.discount
              )
                .toFixed(2)
                .toString()
                .replace(".", ",")}
            </span>
          </p>
          {this.state.cashPayment ? (
            <p className="troco">Troco: R$ {this.state.exchange}</p>
          ) : (
            ``
          )}
        </Total>

        <Button
          margin="24px 0"
          disabled={this.state.confirmDisabled}
          onClick={this.save.bind(this)}
        >
          CONFIRMAR PEDIDO
        </Button>

        {this.state.shouldRenderModalAddress ? (
          <ModalAddress
            onCloseModalAddress={this.closeModalAddress.bind(this)}
          />
        ) : (
          ``
        )}
        {this.state.shouldRenderConfirmed ? (
          <ModalConfirmedOrder
            close={this.closeConfirm.bind(this)}
            id={this.state.confirmID}
            pdf={this.state.confirmPDF}
          />
        ) : (
          ``
        )}
        {this.state.shouldRenderModalObs ? (
          <ModalObs
            close={this.closeModalObs.bind(this)}
            obj={this.state.obs}
          />
        ) : (
          ``
        )}

        {this.state.shouldRenderModalCreditCards && (
          <ModalCreditCards
            close={this.closeModalCreditCards.bind(this)}
            onChange={this.onChangeCreditCard.bind(this)}
            active={this.state.creditCard}
          />
        )}
      </Block>
    );
  }
}

NewOrderData.contextType = StockContext;

export default NewOrderData;
