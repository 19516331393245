import React from 'react';
import styled from 'styled-components'
import { DragDropContext } from 'react-beautiful-dnd';

import Axios from '../Axios';
import Header from '../components/Header';
import Menu from '../components/Menu';
import variables from '../assets/styles/variables'

import CardList from '../components/CardList'
import ModalOrderDetails from '../components/ModalOrderDetails';
import ModalObs from '../components/ModalObs';
import ModalAddress from '../components/ModalAddress';
import ModalPayment from '../components/ModalPayment';
import ModalCancel from '../components/ModalCancel';
import AlertModal from "../components/AlertModal";

const OrdersSection = styled.section`
  display: flex;
  height: calc(100vh - 98px);

  .orders__section{
    width: 25%;
    border-right: 1px solid #D8D8D8;
    padding: 16px;

    &:last-child{
      border: none;
    }

    .list-orders{
      height: calc(100% - 20px);
      overflow: auto;
    }
  }
`

const Title = styled.h2`
  color: #515151;
  font-size: 17px;
  line-height: 19px;
  font-family: ${variables.body.fontFamily};
  margin-bottom: 16px;
`

class Orders extends React.Component {

  state = {
    activeOrder: null,
    shouldRenderModalDetails: false,
    shouldRenderModalObs: false,
    shouldRenderModalAddress: false,
    shouldRenderModalPayment: false,
    shouldRenderModalCancel: false,
    obs: null,
    address: null,
    orders: null,
    homeIndex: null,
    isAlertModalOpened: false,
    alertModalTitle: ""
  }

  componentDidMount() {
    this.requestOrders();

    if (localStorage.getItem('editSavedOrder'))
      this.getEditedOrder();

    localStorage.removeItem('address');

    document.title = 'Pedidos - Frita Delivery'
  }

  async getEditedOrder() {
    this.onClickCloseOrderDetails();

    await Axios.get(`/orders/${localStorage.getItem('editSavedOrder')}`)
      .then(response => {
        let order = response.data;
        localStorage.removeItem('editSavedOrder');

        this.setState({ activeOrder: order, shouldRenderModalDetails: true });
      });
  }

  async requestOrders() {
    return await Axios.get('/orders')
      .then(response => {
        let orders = response.data.rows.sort(function (a, b) {
          return b.id - a.id;
        });
        this.setState({ orders })
      });
  }

  async onClickOrderDetails(id) {
    await Axios.get('/products')
      .then(response => {
        let products = response.data.rows;
        window.localStorage.setItem('products', JSON.stringify(products));

        let order = this.getOrderById(id)
        this.setState({ activeOrder: order, shouldRenderModalDetails: true });
      });
  }

  onClickCloseOrderDetails(id) {
    this.setState({ activeOrder: false, shouldRenderModalDetails: false });

    if (id) {
      let orders = [...this.state.orders],
        order = { ...this.getOrderById(id) };

      order.status = 'cancelled';
      orders[this.state.orders.indexOf(this.getOrderById(id))] = order;

      this.setState({ orders });
    }
  }

  getOrderById(id) {
    return this.state.orders.filter(order => order.id === id)[0];
  }

  getNextStatus(current) {
    let status = ['pending', 'doing', 'ready', 'delivered'],
      currentIndex = status.indexOf(current);

    return status[currentIndex + 1];
  }

  updateOrderStatus(id, status) {
    let orders = [...this.state.orders],
      order = { ...this.getOrderById(parseInt(id)) };

    order.active = true;
    order.status = status;
    orders[this.state.orders.indexOf(this.getOrderById(parseInt(id)))] = order;

    this.setState({ orders });
  }

  async onClickMoveOrder(id, current, next = this.getNextStatus(current), update = true) {
    await Axios.post(`/orders/${id}/move`, {
      status: next
    }).then(response => {
      if (response.status === 200 && update) {
        this.updateOrderStatus(id, next);
      }
    }).catch(response => {
      this.updateOrderStatus(id, current);
      this.setState({ isAlertModalOpened: true, alertModalTitle: 'Houve um problema ao mover o card!' })
    });
  }

  onClickOpenModalCancel() {
    this.setState({
      shouldRenderModalCancel: true
    })
  }

  onClickCloseModalCancel() {
    let data = {
      shouldRenderModalCancel: false
    }
    this.setState(data)
  }

  onClickOpenModalObs(obs) {
    this.setState({
      obs,
      shouldRenderModalObs: true
    })
  }

  onClickCloseModalObs(obs) {
    let data = {
      obs: null,
      shouldRenderModalObs: false,
    };

    if (obs) {
      let active = this.state.activeOrder;
      active.items.map((p) => p.id === this.state.obs.id ? p.observations = obs : ``);

      data.activeOrder = active;
    }
    this.setState(data)
  }

  onClickOpenModalAddress(address) {
    this.setState({
      address,
      shouldRenderModalAddress: true
    })
  }

  onClickCloseModalAddress(address) {
    this.setState({
      address: null,
      shouldRenderModalAddress: false
    });

    if (address?.id) {
      Axios.put(`orders/${this.state.activeOrder.id}`, {
        "isDelivery": this.state.activeOrder.isDelivery,
        "address_id": address.id,
        "paymentMode": this.state.activeOrder.paymentMode
      }).then(() => {
        let order = this.state.activeOrder;
        order.address = address;

        this.setState({
          activeOrder: order
        })
      }).catch((res) => console.log(res));
    }
  }

  onClickOpenModalPayment() {
    this.setState({
      shouldRenderModalPayment: true
    })
  }

  onClickCloseModalPayment(obj) {
    let order = this.state.activeOrder;

    if (obj.paymentMode) {
      order.paymentMode = obj.paymentMode
      order.flag = obj.flag
    }

    this.setState({
      shouldRenderModalPayment: false,
      activeOrder: order
    });
  }

  onDragStart(start) {
    this.setState({ homeIndex: start.source.droppableId });
  }

  onDragEnd(result) {
    this.setState({ homeIndex: null });

    if (!result.destination) return;
    if (result.destination.droppableId === result.source.droppableId) return;

    let id = result.draggableId,
      current = result.source.droppableId,
      next = result.destination.droppableId;


    this.updateOrderStatus(id, next);
    this.onClickMoveOrder(id, current, next, false);
  }

  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <Menu />
        <main>
          <div>
            <OrdersSection className="orders">
              <DragDropContext
                onDragStart={this.onDragStart.bind(this)}
                onDragEnd={this.onDragEnd.bind(this)}
              >
                <div className="orders__section">
                  <Title>Pedidos Salão</Title>

                  <CardList
                    orders={this.state.orders}
                    id="pending"
                    type="store"
                    isDropDisabled={this.state.homeIndex === "pending"}
                    onClickOrderDetails={this.onClickOrderDetails.bind(
                      this
                    )}
                    onClickMoveOrder={this.onClickMoveOrder.bind(this)}
                  />
                </div>
                <div className="orders__section">
                  <Title>Pedidos App</Title>

                  <CardList
                    orders={this.state.orders}
                    id="pending"
                    type="mobile"
                    isDropDisabled={this.state.homeIndex === "pending"}
                    onClickOrderDetails={this.onClickOrderDetails.bind(
                      this
                    )}
                    onClickMoveOrder={this.onClickMoveOrder.bind(this)}
                  />
                </div>
                <div className="orders__section">
                  <Title>Em Produção</Title>

                  <CardList
                    orders={this.state.orders}
                    id="doing"
                    isDropDisabled={this.state.homeIndex === "doing"}
                    onClickOrderDetails={this.onClickOrderDetails.bind(
                      this
                    )}
                    onClickMoveOrder={this.onClickMoveOrder.bind(this)}
                  />
                </div>
                <div className="orders__section">
                  <Title>Prontos</Title>

                  <CardList
                    orders={this.state.orders}
                    id="ready"
                    isDropDisabled={this.state.homeIndex === "ready"}
                    onClickOrderDetails={this.onClickOrderDetails.bind(
                      this
                    )}
                    onClickMoveOrder={this.onClickMoveOrder.bind(this)}
                  />
                </div>
                <div className="orders__section">
                  <Title>Concluídos</Title>

                  <CardList
                    orders={this.state.orders}
                    id="delivered"
                    isDropDisabled={this.state.homeIndex === "delivered"}
                    isDragDisabled={true}
                    onClickOrderDetails={this.onClickOrderDetails.bind(
                      this
                    )}
                    onClickMoveOrder={this.onClickMoveOrder.bind(this)}
                  />
                </div>
              </DragDropContext>
            </OrdersSection>

            <div>
              {this.state.shouldRenderModalDetails ? (
                <ModalOrderDetails
                  order={this.state.activeOrder}
                  onClose={this.onClickCloseOrderDetails.bind(this)}
                  onClickMoveOrder={this.onClickMoveOrder.bind(this)}
                  openModalObs={this.onClickOpenModalObs.bind(this)}
                  openModalAddress={this.onClickOpenModalAddress.bind(this)}
                  openModalPayment={this.onClickOpenModalPayment.bind(this)}
                  openModalCancel={this.onClickOpenModalCancel.bind(this)}
                  isNotCancelable={
                    this.state.activeOrder.status === "delivered"
                  }
                  activeModal={() => {
                    this.setState({ isAlertModalOpened: true, alertModalTitle: 'Esse pedido ainda não possui nota fiscal' })
                  }}
                />
              ) : (
                ""
              )}

              {this.state.shouldRenderModalObs ? (
                <ModalObs
                  edit={true}
                  close={this.onClickCloseModalObs.bind(this)}
                  obj={this.state.obs}
                />
              ) : (
                ""
              )}
              {this.state.shouldRenderModalAddress ? (
                <ModalAddress
                  address={this.state.address}
                  edit={true}
                  onCloseModalAddress={this.onClickCloseModalAddress.bind(
                    this
                  )}
                />
              ) : (
                ""
              )}
              {this.state.shouldRenderModalPayment ? (
                <ModalPayment
                  order={this.state.activeOrder}
                  edit={true}
                  onClose={this.onClickCloseModalPayment.bind(this)}
                />
              ) : (
                ""
              )}
              {this.state.shouldRenderModalCancel ? (
                <ModalCancel
                  order={this.state.activeOrder}
                  onCancel={(id) => this.onClickCloseOrderDetails(id)}
                  close={this.onClickCloseModalCancel.bind(this)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          {this.state.isAlertModalOpened && (
            <div>
              <AlertModal
                title={this.state.alertModalTitle}
                onClose={() => {
                  this.setState({ isAlertModalOpened: false });
                }}
              />
            </div>
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default Orders;