import React from "react";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import Axios from "../Axios";
import { first } from "lodash";
import Frita from "../assets/svg/frita-logo.png";

const GlobalStyles = createGlobalStyle`
    html, body{
        background-color: #2a2a29;
    }
`;

const PanelArea = styled.section`
  height: 100vh;
  padding: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const MainOrder = styled.div`
  background-color: #1b1a19;
  width: 60%;
  height: 100%;
  padding: 56px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: #ffffff;
    font-family: Proxima;
    font-size: 44px;
    font-weight: 700;
    line-height: 48px;
    position: absolute;
    top: 56px;
  }

  h1 {
    color: #ffffff;
    font-family: Proxima;
    font-size: 260px;
    font-weight: 700;
    letter-spacing: 25px;
    line-height: 288px;
    margin-top: 48px;
  }
`;

const Orders = styled.div`
  margin-left: 30px;
  width: 40%;
  height: 100%;
  text-align: center;
`;

const OrdersBlock = styled.div`
    background-color: #1B1A19;
    padding: 56px 47px;
    width: 100%;
    height: 80%;

    h2{
        color: #FFFFFF;
        font-family: Proxima;
        font-size: 44px;
        font-weight: 700;
        line-height: 48px;
    }

    h3{
        color: #fff;
        font-family: Proxima;
        font-size: 84px;
        font-weight: 700;
        letter-spacing: 7.5px;
        line-height: 86px;
        margin:24px;
    }

    .orders{
        height: 80%;
    }
`;

const ImageBlock = styled.div`
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 106px;
  }
`;

class Panel extends React.Component {
  state = {
    orders: [],
  };

  componentDidMount() {
    setInterval(() => {
      this.requestOrders();
	}, 4000);
	
	document.title = 'Painel - Frita Delivery'
  }

  async requestOrders() {
    return await Axios.get("/orders").then((response) => {
      let orders = response.data.rows.filter((res) => res.status === "delivered");

      orders = orders.sort(function (a, b) {
        let adate = new Date(a.updatedAt),
            bdate = new Date(b.updatedAt);
        return bdate.getTime() - adate.getTime();
      });

      this.setState({ orders });
    });
  }

  render() {
    let current = this.state.orders.length > 0 ? first(this.state.orders).id : '';
    return (
      <React.Fragment>
        <GlobalStyles />
        <PanelArea>
          <MainOrder>
            <h2>Seu pedido está pronto!!</h2>
            <h1>{current}</h1>
          </MainOrder>
          <Orders>
            <ImageBlock>
              <img alt="Frita Logo" src={Frita}></img>
            </ImageBlock>
            <OrdersBlock>
              <h2>Útimos pedidos</h2>
              <div className="orders">
                {this.state.orders.slice(1, 4).map((res) => {
                  return <h3>{res.id}</h3>;
                })}
              </div>
            </OrdersBlock>
          </Orders>
        </PanelArea>
      </React.Fragment>
    );
  }
}

export default Panel;
