import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import Axios from "../Axios";
import AlertModal from "../components/AlertModal";

const Table = styled.table`
  color: #000000;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  border: 2px solid #000000;
  width: 100%;

  thead {
    border-bottom: 2px solid #000;

    .info {
      padding: 6px 16px;
      text-align: right;

      .name,
      .hora {
        padding-right: 16px;
      }

      .name,
      .hora,
      .entrega {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
    }

    .id {
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      padding: 6px 16px;
    }
  }

  tbody {
    tr:not(.head) {
      td {
        border-right: 2px dashed #000;
        padding: 14px 5px;
        border-bottom: 2px solid #000;

        &:last-child {
          max-width: 40%;
        }
      }
    }

    tr.head {
      td {
        padding: 5px;
        border-right: 2px dashed #000;

        span {
          border: 1px solid #000000;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 20px;
        }
      }
    }
  }
`;
let Global = createGlobalStyle`
    html, body{
        height: 100%;
        overflow: visible;
    }

    tr{
        page-break-inside: avoid;
    }
`;
export default class Print extends React.Component {
  state = {
    order: null,
    isAlertModalOpened: false,
    alertModalTitle: "",
  };

  componentDidMount() {
    let order = this.getOrder(this.props.match.params.pedido);
    order.then((res) => {
      this.setState(
        {
          order: res.data,
        },
        () => {
          window.print();
        }
      );
	});
	
	document.title = 'Imprimir Pedido - Frita Delivery'
  }

  async getOrder(id) {
    try {
      return await Axios.get(`/orders/${id}`);
    } catch (error) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle:
          "Houve um erro ao tentar editar o pedido, tente novamente!",
      });
    }
  }

  render() {
    let address = this.state.order ? this.state.order.address : {};

    return this.state.order ? (
      <React.Fragment>
        <Global />
        <Table>
          <thead>
            <tr>
              <td colSpan="2" className="id">
                Pedido #{this.state.order.id}
              </td>
              <td colSpan="2" className="info" style={{ width: "100%" }}>
                <span className="name">{this.state.order.name}</span>
                <span className="hora">
                  Realizado:{" "}
                  {`${new Date(
                    this.state.order.createdAt
                  ).getHours()}h${new Date(
                    this.state.order.createdAt
                  ).getMinutes()}`}
                </span>
                <span className="entrega">
                  Entrega: {this.state.order.deliveryTime}
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="head">
              <td>
                <span>Item</span>
              </td>
              <td>
                <span>Removidos</span>
              </td>
              <td>
                <span>Adicionados</span>
              </td>
              <td>
                <span>Observações</span>
              </td>
            </tr>
            {this.state.order.items.map((item) => {
              return (
                <tr>
                  <td>{item.product.name}</td>
                  <td>
                    {Array.from(item.extra.exclude_ingredients).map(
                      (e, index) => {
                        return Array.from(item.extra.exclude_ingredients)
                          .length -
                          1 ===
                          index
                          ? `${e.name}`
                          : `${e.name}, `;
                      }
                    )}
                  </td>
                  <td>
                    {Array.from(item.extra.add_extra_ingredients).map(
                      (e, index) => {
                        return Array.from(item.extra.add_extra_ingredients)
                          .length -
                          1 ===
                          index
                          ? `${e.quantity} - ${e.ingredient.name}`
                          : `${e.quantity} - ${e.ingredient.name}, `;
                      }
                    )}
                  </td>
                  <td width="40%">
                    {item.observations}
                    {(item.category_id === 1 || item.category_id === 2) && (
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        <strong style={{ fontWeight: 700 }}>
                          Condimentos:{" "}
                        </strong>
                        {item.spices.map((con, i) => {
                          return item.spices.length - 1 === i
                            ? `${con.quantity} - ${con.spice.name}`
                            : `${con.quantity} - ${con.spice.name}, `;
                        })}
                      </p>
                    )}
                  </td>
                </tr>
              );
            })}
            {address && (
              <tr>
                <td colSpan="4">
                  Endereço: {address.streetName}, {address.streetNumber}.
                  Complemento: {address.complement}. CEP: {address.cep}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {this.state.isAlertModalOpened && (
          <div>
            <AlertModal
              title={this.state.alertModalTitle}
              onClose={() => {
                this.setState({ isAlertModalOpened: false });
              }}
            />
          </div>
        )}
      </React.Fragment>
    ) : (
      ``
    );
  }
}
