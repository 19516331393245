import axios from "axios";
import { authHeader } from "./helpers/authHeader";

const Axios = axios.create({
    baseURL: 'https://delivery.fritabatatariaesaladeria.com.br/',
    timeout: 15000,
    headers: authHeader()
});

export default Axios;
