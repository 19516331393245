import React from "react";
import styled from "styled-components";
import NewOrderCategories from "../components/NewOrderCategories";
import NewOrderData from "../components/NewOrderData";
import ProductListContainer from "../components/ProductListContainer";
import NewOrderIngredients from "../components/NewOrderIngredients";
import Axios from "../Axios";
import Header from "../components/Header";
import Menu from "../components/Menu";
import { Column } from "../components/Columns";
import AlertModal from "../components/AlertModal";
import SearchUser from "../components/SearchUser";
import ModalCreateUser from "../components/ModalCreateUser";

import { Redirect } from "react-router-dom";

const NewOrderBlock = styled.section`
  display: flex;

  .new-order-step {
    margin: 15px;
    width: 23.3333333%;
    height: 100vh;

    &:last-child {
      width: 30%;
      margin: 0;
    }
  }
`;

class NewOrder extends React.Component {
  state = {
    shouldRenderStep2: false,
    shouldRenderStep3: false,
    shouldRenderStep4: true,
    category: null,
    product: null,
    inventory: [],
    edit: null,
    editSavedOrder: false,
    editSavedOrderItems: [],
    redirect: false,
    user: null,
    shouldRenderModalCreateUser: false,
    exchange: null,
    payment: "",
    studentDiscount: false,
    discount: 0.0,
    isAlertModalOpened: false,
    alertModalTitle: "",
  };

  handleCategoryClick(category) {
    this.setState({
      shouldRenderStep2: false,
      shouldRenderStep3: false,
      product: null,
    });

    setTimeout(() => {
      this.setState({ shouldRenderStep2: true, category });
    }, 200);
  }

  updateResume() {
    this.setState({ shouldRenderStep4: false }, () => {
      this.setState({ shouldRenderStep4: true });
    });
  }

  onChangeStudentDiscout() {
    this.setState((oldState) => ({
      studentDiscount: !oldState.studentDiscount,
    }));
  }

  handleProductClick(product) {
    this.setState({ shouldRenderStep3: false });

    setTimeout(() => {
      if (product.ingredients.length !== 0 || product.isSpecialFries) {
        this.setState({ shouldRenderStep3: true, product: product });
        return;
      }

      this.setState({ product }, () => {
        this.onAddOrderClick();
      });
    }, 200);
  }

  getSelectedIngredients() {
    let ingredientsInput = document.querySelectorAll(
        '.new-order-step[step="3"] [data-ingredient] input:checked'
      ),
      ingredients = [];

    for (let i = 0; i < ingredientsInput.length; i++) {
      ingredients.push(ingredientsInput[i].id);
    }

    return ingredients;
  }

  getIngredientsFromProduct(product) {
    if (product.ingredients) return product.ingredients.map((ing) => ing.id);
    return [];
  }

  getExtraIngredients(product, selectedIngredients) {
    let sel = selectedIngredients
      .filter(
        (id) =>
          this.getIngredientsFromProduct(product).indexOf(parseInt(id)) < 0
      )
      .map((id) => parseInt(id));
    let ingredients = [];

    for (let i = 0; i < sel.length; i++) {
      let ingredientsqt = document.querySelector(
        `[name="ingredient-qt-${sel[i]}"]`
      );
      let qt = ingredientsqt ? parseInt(ingredientsqt.value) : 1;
      ingredients.push({ ingredient: sel[i], quantity: qt });
    }

    return ingredients;
  }

  getRemovedIngredients(product, selectedIngredients) {
    if (product.ingredients)
      return product.ingredients
        .filter((ing) => selectedIngredients.indexOf(ing.id.toString()) < 0)
        .map((ing) => parseInt(ing.id));
    return [];
  }

  async loadCondiments() {
    await Axios.get("/spices").then((res) => {
      this.setState({ condimentsList: res.data.rows });
    });
  }

  addSpices() {
    const x = this.state.condimentsList
      .filter((c) => {
        return c.default === true;
      })
      .map((c) => {
        return { spice: c.id, quantity: 2 };
      });

    return x;
  }

  onAddOrderClick() {
    let ingredients = this.getSelectedIngredients(),
      product = this.state.editSavedOrder
        ? JSON.parse(localStorage.getItem("products")).filter(
            (p) => p.id === this.state.product.product_id
          )[0]
        : this.state.product;

    if (ingredients.length === 0 && product.ingredients.length > 0) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle: "Selecione pelo menos um ingrediente.",
      });
      return;
    }

    let extra =
        ingredients.length > 0
          ? this.getExtraIngredients(product, ingredients)
          : [],
      removed =
        ingredients.length > 0
          ? this.getRemovedIngredients(product, ingredients)
          : [],
      order = {
        product_id: product.id,
        exclude_ingredients: removed,
        add_extra_ingredients: extra,
        quantity: 1,
        spices: this.addSpices(),
      };

    if (this.state.editSavedOrder) {
      this.addSavedOrder(removed, extra);

      return;
    }

    if (this.state.edit) {
      order.ref = product.inventory_ref;
      order.observations = product.observations;
      order.spices = product.spices.map((e) => ({
        spice: e.spice.id,
        quantity: e.quantity,
      }));

      this.updateOrder(order).then(() => {
        this.setState(
          {
            shouldRenderStep2: true,
            shouldRenderStep3: false,
            shouldRenderStep4: false,
            edit: false,
          },
          () => {
            this.setState({ shouldRenderStep4: true });
          }
        );
      });

      return;
    }
    this.addOrder(order).then((response) => {
      let inventory = this.state.inventory;
      if (!response) return;
      inventory.push(response.data.ref);
      this.setState(
        {
          inventory,
          shouldRenderStep2: false,
          shouldRenderStep3: false,
          shouldRenderStep4: false,
        },
        () => {
          this.setState({
            shouldRenderStep4: true,
            shouldRenderStep2: true,
            product: null,
          });
        }
      );

      localStorage.removeItem("address");
    });
  }

  async addOrder(order) {
    try {
      return await Axios.post("/inventory/items", order);
    } catch (error) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle:
          "Houve um erro ao processar seu pedido, por favor tente novamente!",
      });
    }
  }

  async updateOrder(order) {
    try {
      return await Axios.put(`/inventory/items/update/${order.ref}`, order);
    } catch (error) {
      this.setState({
        shouldRenderStep2: false,
        shouldRenderStep3: false,
        shouldRenderStep4: false,
        edit: false,
        product: null,
        category: null,
        isAlertModalOpened: true,
        alertModalTitle:
          "Houve um erro ao editar seu pedido, por favor tente novamente!",
      });
    }
  }

  onClickEditOrder(order) {
    this.setState(
      {
        shouldRenderStep2: false,
        shouldRenderStep3: false,
        shouldRenderStep4: false,
      },
      () => {
        let state = {
          shouldRenderStep2: true,
          shouldRenderStep3: false,
          shouldRenderStep4: true,
          category: order.category,
          edit: true,
        };

        if (order.ingredients.length !== 0) {
          state = {
            shouldRenderStep2: true,
            shouldRenderStep3: true,
            shouldRenderStep4: true,
            category: order.category,
            edit: true,
            product: order,
          };
        }

        this.setState(state);
      }
    );
  }

  onDuplicateOrder(product) {
    const order = {
      product_id: product.id,
      exclude_ingredients: product.extra.exclude_ingredients.map((i) => i.id),
      add_extra_ingredients: product.extra.add_extra_ingredients.map((i) => ({
        ingredient: i.ingredient.id,
        quantity: i.quantity,
      })),
      quantity: 1,
      spices: this.addSpices(),
    };

    this.addOrder(order).then((response) => {
      let inventory = this.state.inventory;
      if (!response) return;
      inventory.push(response.data.ref);
      this.setState(
        {
          inventory,
          shouldRenderStep2: false,
          shouldRenderStep3: false,
          shouldRenderStep4: false,
        },
        () => {
          this.setState({
            shouldRenderStep4: true,
            shouldRenderStep2: true,
            product: null,
          });
        }
      );
    });
  }

  async onClickRemoveOrder(order) {
    await Axios.delete(`/inventory/items/${order.inventory_ref}`).then(
      (res) => {
        if (res.status) {
          this.setState(
            {
              shouldRenderStep4: false,
            },
            () => {
              this.setState({ shouldRenderStep4: true });
            }
          );
        }
      }
    );
  }

  async getOrder(id) {
    try {
      return await Axios.get(`/orders/${id}`);
    } catch (error) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle:
          "Houve um erro ao tentar editar o pedido, tente novamente!",
      });
    }
  }

  clearInventory() {
    this.setState({ inventory: [] });
  }

  componentWillUnmount() {
    if (!this.props.clients && this.state.inventory.length > 0) {
      const isLeaving = window.confirm(
        "Sair dessa página implica na limpeza do seu inventário. Deseja prosseguir?"
      );
      if (isLeaving) {
        Axios.delete("/inventory");
      }
    }
  }

  componentDidMount() {
    if (this.props.location?.state?.editingOrder) {
      let order = this.getOrder(this.props.location.state.editingOrder);
      order.then((res) => {
        if (res.data) {
          res.data.items.forEach((item) => {
            const spices = item.spices.map((spice) => {
              return {
                spice: spice.spice.id,
                default: spice.spice.default,
                quantity: Number.parseInt(spice.quantity),
              };
            });
            const add_extra_ingredients = item.extra.add_extra_ingredients.map(
              (ing) => {
                return {
                  ingredient: ing.ingredient.id,
                  quantity: Number.parseInt(ing.quantity),
                };
              }
            );
            const exclude_ingredients = item.extra.exclude_ingredients.map(
              (ing) => {
                return ing.id;
              }
            );
            this.addOrder({
              product_id: item.product_id,
              add_extra_ingredients,
              exclude_ingredients,
              spices,
              observations: item.observations,
              statusPayment: item.statusPayment,
            }).then((response) => {
              this.setState({ inventory: [response.data.ref] });
            });
          });
          setTimeout(() => {
            this.setState({
              orderID: this.props.location.state.editingOrder,
              shouldRenderStep2: true,
              shouldRenderStep3: false,
              shouldRenderStep4: true,
              user: res.data.createdFor,
              category: 1,
              isEditingOrder: true,
            });
          }, 1000);
        }
      });
    }
    this.loadCondiments();
    if (this.props.match.params.pedido) {
      this.loadOrder();
    }

    if (!this.props.clients) {
      this.loadProfile();
    }

    document.title = 'Novo Pedido - Frita Delivery'
  }

  async loadProfile() {
    const response = await Axios.get("/auth/me");

    this.setState({
      user: response.data,
    });
  }

  loadOrder() {
    this.setState(
      {
        editSavedOrder: true,
        orderID: this.props.match.params.pedido,
        item: this.props.match.params.item,
      },
      () => {
        let order = this.getOrder(this.props.match.params.pedido);
        order.then((res) => {
          let orderData = res.data;
          let product = orderData.items.filter(
            (i) => i.id === parseInt(this.state.item)
          )[0];

          if (res.data)
            this.setState({
              product: product,
              editSavedOrder: true,
              edit: true,
              editSavedOrderData: orderData,
              category: product.category_id,
              shouldRenderStep2: true,
              shouldRenderStep3: true,
              shouldRenderStep4: false,
              inventory: product,
            });
        });
      }
    );
  }

  async updateSavedOrder(obj) {
    try {
      let spices = [];
      for (let s of this.state.inventory.spices)
        spices.push({
          spice: s.spice.id,
          quantity: s.quantity,
          default: s.spice.default,
        });
      const body = {
        product_id: this.state.inventory.product_id,
        add_extra_ingredients: obj.extra.add_extra_ingredients,
        exclude_ingredients: obj.extra.exclude_ingredients,
        spices,
        observations: this.state.inventory.observations,
        statusPayment: this.state.inventory.statusPayment,
      };
      await Axios.delete("/inventory");
      await Axios.post(`/inventory/items`, body);
      return Axios.put(
        `/order-products/${this.state.orderID}/${this.state.item}`
      );
    } catch (error) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle:
          "Houve um erro ao editar seu pedido, por favor tente novamente!",
      });
    }
  }

  addSavedOrder(removed, extra) {
    let obj = {
      extra: {
        exclude_ingredients: removed,
        add_extra_ingredients: extra,
      },
    };

    let response = this.updateSavedOrder(obj);

    response.then((res) => {
      localStorage.setItem("editSavedOrder", this.props.match.params.pedido);
      this.setState({ redirect: true });
    });
  }

  onUserSelection(user, goTo = true) {
    user = goTo ? user : null;
    Axios.delete("/inventory").then(() => {
      this.setState({
        user,
        shouldRenderModalCreateUser: false,
        category: 1,
        shouldRenderStep2: true,
      });
    });
  }

  openModalCreateUser(editUser) {
    this.setState({ shouldRenderModalCreateUser: true, editUser });
  }

  onCloseModalCreateUser() {
    this.setState({ shouldRenderModalCreateUser: false });
  }

  onChangeExchange(e) {
    this.setState({ exchange: e.target.value });
  }

  onChangePayment(payment) {
    this.setState({ payment: payment });
  }

  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <Menu editSavedOrder={this.state.editSavedOrder} />
        <main>
          {this.state.redirect ? <Redirect to="/" /> : ``}

          {!this.state.user &&
          !this.props.match.params.pedido &&
          this.props.clients ? (
            <>
              <SearchUser
                onEdit={this.onUserSelection.bind(this)}
                modal={this.openModalCreateUser.bind(this)}
                onUserSelection={
                  this.props.clients
                    ? this.openModalCreateUser.bind(this)
                    : this.onUserSelection.bind(this)
                }
              ></SearchUser>

              {this.state.shouldRenderModalCreateUser ? (
                <ModalCreateUser
                  close={this.onCloseModalCreateUser.bind(this)}
                  save={this.onUserSelection.bind(this)}
                  user={this.state.editUser}
                ></ModalCreateUser>
              ) : (
                ``
              )}
            </>
          ) : (
            <NewOrderBlock>
              <div className="new-order-step" step="1">
                <NewOrderCategories
                  edit={this.state.edit}
                  editSavedOrder={this.state.editSavedOrder}
                  active={this.state.category}
                  express={this.props.express}
                  handleCategoryClick={this.handleCategoryClick.bind(this)}
                />
              </div>

              <Column className="new-order-step" step="2">
                {this.state.shouldRenderStep2 ? (
                  <ProductListContainer
                    edit={this.state.edit}
                    editSavedOrder={this.state.editSavedOrder}
                    active={this.state.product ? this.state.product.id : ""}
                    express={this.props.express}
                    category={this.state.category}
                    handleProductClick={this.handleProductClick.bind(this)}
                  />
                ) : (
                  ``
                )}
              </Column>

              <Column className="new-order-step" step="3">
                {this.state.shouldRenderStep3 ? (
                  <NewOrderIngredients
                    edit={this.state.edit}
                    editSavedOrder={this.state.editSavedOrder}
                    product={this.state.product}
                    onAddOrderClick={this.onAddOrderClick.bind(this)}
                  ></NewOrderIngredients>
                ) : (
                  ``
                )}
              </Column>

              <div className="new-order-step" step="4">
                {this.state.shouldRenderStep4 ? (
                  <NewOrderData
                    payment={this.state.payment}
                    onChangePayment={this.onChangePayment.bind(this)}
                    onChangeExchange={this.onChangeExchange.bind(this)}
                    exchange={this.state.exchange}
                    user={this.state.user}
                    edit={this.state.edit}
                    editSavedOrder={this.state.editSavedOrder}
                    updateResume={this.updateResume.bind(this)}
                    onDuplicateOrder={this.onDuplicateOrder.bind(this)}
                    onClickEditOrder={this.onClickEditOrder.bind(this)}
                    onClickRemoveOrder={this.onClickRemoveOrder.bind(this)}
                    inventory={this.state.inventory}
                    onChangeStudentDiscout={this.onChangeStudentDiscout.bind(
                      this
                    )}
                    studentDiscount={this.state.studentDiscount}
                    discount={this.state.discount}
                    isEditingOrder={this.state.isEditingOrder}
                    editingOrderId={this.state.orderID}
                    clearInventory={this.clearInventory.bind(this)}
                  />
                ) : (
                  ``
                )}
              </div>
            </NewOrderBlock>
          )}
          {this.state.isAlertModalOpened && (
            <div>
              <AlertModal
                title={this.state.alertModalTitle}
                onClose={() => {
                  this.setState({ isAlertModalOpened: false });
                }}
              />
            </div>
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default NewOrder;
