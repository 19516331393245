import React from "react";
import styled, { keyframes } from "styled-components";
import Axios from "../Axios.js";
import NewOrderBlock from "./NewOrderBlock";
import { InputText, InputContainer } from "./InputText";
import { ReactComponent as Next } from "../assets/svg/next-light.svg";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Numeric from "./Numeric";
import Checkbox from "./Checkbox";

import { fadeIn } from "react-animations";
const animation = keyframes`${fadeIn}`;

const IngredientQuantity = styled.div`
  animation: 1s ${animation};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-left: 32px;
`;

const AddOrderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 24px 24px;
`;

const AddOrder = styled.button`
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #f29448;
  display: inline-flex;
  align-items: flex-end;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;

  svg {
    height: 18px;
    margin-left: 6px;
  }
`;

const Ingredients = styled.ul`
  list-style: none;
  padding: 0px 24px;
  height: calc(100vh - 282px);
  overflow: auto;
`;

const Ingredient = styled.li`
  position: relative;
  padding: 24px;

  &:first-child {
    padding-top: 0;
  }

  &:after {
    content: "";
    display: block;
    height: 2px;
    background-color: #cccccc;
    position: absolute;
    bottom: 0;
    width: calc(100% - 48px);
  }

  label {
    color: #414141;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;

    span {
      margin-left: auto;
      color: #979797;
    }
  }

  &.extra-ingredient {
    label {
      color: #979797;
    }
  }
`;

const P = styled.p`
  display: inline-flex;
  color: #525252;
  font-family: ArialMT;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding-right: 15px;
`;
class NewOrderIngredients extends React.Component {
  state = {
    ingredients: [],
    visibleQt: [],
    product: [],
  };

  componentDidMount() {
    this.getProducts();
    this.getIngredients();

    if (this.props.editSavedOrder) {
      this.setState({
        product: JSON.parse(localStorage.getItem("products")).filter(
          (p) => p.id === this.props.product.product_id
        )[0],
        visibleQt: this.props.product.extra.add_extra_ingredients.map(
          (i) => `${i.ingredient.id}`
        ),
      });
    }
  }

  async getProducts() {
    await Axios.get("/products").then((response) => {
      let products = response.data.rows;
      window.localStorage.setItem("products", JSON.stringify(products));
    });
  }

  async getIngredients() {
    const response = await Axios.get("/ingredients")
    
      response.data = response.data.sort(function (a, b) {
        return a.position - b.position || a.name.localeCompare(b.name);
      });

      if (this.props.product.isSpecialFries)
        response.data = response.data.filter((i) => {
          return i.showInPortions;
        });

      this.setState({
        ingredients: response.data.filter(ingredient => ingredient.category_id === this.props.product.category_id),
        allIngredients: response.data,
      });
  }

  toggleQuantity(e, isID) {
    let id = isID ? e : e.currentTarget.getAttribute("for");
    let temp = [...this.state.visibleQt];

    if (this.state.visibleQt.includes(id)) {
      const index = temp.indexOf(id);

      if (index > -1) temp.splice(index, 1);
      this.setState({ visibleQt: temp });
      return;
    }

    temp.push(id);
    this.setState({ visibleQt: temp });
  }

  onNumericChange() {}

  searchIngredient() {
    this.setState({ ingredients: this.state.allIngredients }, () => {
      if (this.searchInput.value === "") return false;

      const ingredients = this.state.ingredients.filter((i) => {
        return i.name.toLowerCase().indexOf(this.searchInput.value) > -1; // true
      });

      this.setState({ ingredients });
    });
  }

  onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    let id = parseInt(result.draggableId),
      position = result.destination.index;

    this.updateIngredientPosition(id, position);
  }

  async updateIngredientPosition(id, position) {
    let ingredient = this.state.allIngredients.filter((i) => i.id === id)[0];
    let data = {
      name: ingredient.name,
      price: ingredient.price,
      quantity: ingredient.quantity,
      position,
    };

    await Axios.put(`/ingredients/${id}`, data).then(() => {
      this.getIngredients();
    });
  }

  render() {
    let ingredients = this.props.editSavedOrder
      ? this.state.product.ingredients
      : this.props.product.ingredients;
    return (
      <NewOrderBlock>
        <InputContainer className="search">
          <InputText
            ref={(input) => (this.searchInput = input)}
            onKeyPress={this.searchIngredient.bind(this)}
            onChange={this.searchIngredient.bind(this)}
            placeholder={"Filtrar ingredientes..."}
          ></InputText>
        </InputContainer>
        <AddOrderContainer>
          <AddOrder onClick={this.props.onAddOrderClick}>
            {this.props.edit ? `Finalizar Edição` : `Adicionar`}{" "}
            <Next fill="#F29448"></Next>
          </AddOrder>
        </AddOrderContainer>
        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
          <Droppable droppableId="ingredient-list">
            {(provided, snapshot) => (
              <Ingredients
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                className="ingredients"
                {...provided.droppableProps}
              >
                {ingredients
                  ? ingredients.map((ing) => (
                      <Ingredient key={ing.id} data-ingredient>
                        <Checkbox
                          defaultChecked={
                            this.props.edit &&
                            this.props.product.extra &&
                            this.props.product.extra.exclude_ingredients
                              ? this.props.product.extra.exclude_ingredients.filter(
                                  (i) => i.id === ing.id
                                ).length > 0
                                ? false
                                : true
                              : true
                          }
                          id={ing.id}
                          name={ing.name}
                        ></Checkbox>
                      </Ingredient>
                    ))
                  : ``}
                {this.state.ingredients &&
                  this.state.ingredients.filter((ingredient) => 
                    this.props.product.isSpecialFries
                      ? !!ingredient.showInPortions
                      : !ingredient.showInPortions
                  ).map((ing, index) =>
                    !ingredients ||
                    ingredients.filter((pIng) => pIng.id === ing.id).length ===
                      0 ? (
                      <Draggable draggableId={`${ing.id}`} index={index}>
                        {(provided, snapshot) => (
                          <Ingredient
                            className="extra-ingredient"
                            key={ing.id}
                            data-ingredient
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Checkbox
                              defaultChecked={
                                this.props.edit &&
                                this.props.product.extra &&
                                this.props.product.extra.add_extra_ingredients
                                  ? this.props.product.extra.add_extra_ingredients.filter(
                                      (i) => i.ingredient.id === ing.id
                                    ).length > 0
                                    ? true
                                    : false
                                  : this.state.visibleQt.includes(`${ing.id}`)
                                  ? true
                                  : false
                              }
                              onClick={this.toggleQuantity.bind(this)}
                              id={ing.id}
                              name={ing.name}
                              sub={`R$ ${ing.price.replace(".", ",")}`}
                            ></Checkbox>
                            {this.state.visibleQt.includes(`${ing.id}`) ? (
                              <IngredientQuantity>
                                <P>Quantidade extra</P>
                                <Numeric
                                  defaultValue={
                                    this.props.edit &&
                                    this.props.product.extra &&
                                    this.props.product.extra
                                      .add_extra_ingredients
                                      ? this.props.product.extra.add_extra_ingredients.filter(
                                          (i) => i.ingredient.id === ing.id
                                        ).length > 0
                                        ? this.props.product.extra.add_extra_ingredients.filter(
                                            (i) => i.ingredient.id === ing.id
                                          )[0].quantity
                                        : 1
                                      : 1
                                  }
                                  onChange={this.onNumericChange.bind(this)}
                                  name={`ingredient-qt-${ing.id}`}
                                />
                              </IngredientQuantity>
                            ) : (
                              ``
                            )}
                          </Ingredient>
                        )}
                      </Draggable>
                    ) : (
                      ``
                    )
                  )}
                {provided.placeholder}
              </Ingredients>
            )}
          </Droppable>
        </DragDropContext>
      </NewOrderBlock>
    );
  }
}

export default NewOrderIngredients;
