import React from "react";
import Axios from "../Axios.js";
import { Container, ContainerButton } from "../components/Container";
import styled from "styled-components";
import {
  InputContainer,
  InputText,
  InputTextMask,
  Label,
} from "./InputText.js";

const InputsContainer = styled.div`
  padding: 24px 40px 0 24px;

  button {
    width: 100%;
    border: 1px solid #d18b00;
    border-radius: 4px;
    background: transparent;
    color: #d18b00;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    margin-top: 8px;
  }
`;

class NewOrderCategories extends React.Component {
  state = {
    categories: [],
    active: null,
    userNameInput: this.getUserNameInput(),
    observations: this.getObservationsInput(),
    cpfCnpj: this.getCpfCnpjInput(),
  };

  componentDidMount() {
    this.getCategories();

    this.getUserNameInput();
  }

  getUserNameInput() {
    if (sessionStorage.getItem("userNameInput")?.length > 0) {
      const userNameInput = sessionStorage.getItem("userNameInput");
      this.setState({ userNameInput });
      return userNameInput;
    }

    this.setState({ userNameInput: "" });
    return "";
  }

  getObservationsInput() {
    if (sessionStorage.getItem("observations")?.length > 0) {
      const observations = sessionStorage.getItem("observations");
      this.setState({ observations });
      return observations;
    }

    this.setState({ observations: "" });
    return "";
  }

  getCpfCnpjInput() {
    if (sessionStorage.getItem("cpfCnpj")?.length > 0) {
      const cpfCnpj = sessionStorage.getItem("cpfCnpj");
      this.setState({ cpfCnpj });
      return cpfCnpj;
    }

    this.setState({ cpfCnpj: "" });
    return "";
  }

  handleUserNameInput(e) {
    this.setState({ userNameInput: e.target.value });
    sessionStorage.setItem("userNameInput", e.target.value);
  }

  handleObservationsInput(e) {
    this.setState({ observations: e.target.value });
    sessionStorage.setItem("observations", e.target.value);
  }

  async getCategories() {
    return await Axios.get(
      this?.props?.express ? "/categories/express" : "/categories"
    ).then((response) => {
      this?.props?.express
        ? this.setState({ categories: response.data })
        : this.setState({ categories: response.data.rows });
    });
  }

  handleChange = (ev) => {
    if (ev.target.value.replace(/[^0-9]/g, "").length <= 11) {
      this.setState({
        cpfCnpjMask: "999.999.999-99",
      });
    }

    this.setState({ cpfCnpj: ev.target.value });
    sessionStorage.setItem("cpfCnpj", ev.target.value);
  };

  render() {
    return (
      <Container className="categories">
        <InputsContainer>
          <InputContainer className="input-container">
            <Label>Nome</Label>
            <InputText
              onChange={(e) => this.handleUserNameInput(e)}
              placeholder="Informe o nome cliente"
              value={this.state.userNameInput}
            />
          </InputContainer>
          <InputContainer className="input-container">
            <Label>CPF/CNPJ</Label>
            <InputTextMask
              ref={(input) => {
                this.input = input;
              }}
              mask={this.state.cpfCnpjMask}
              maskChar="_"
              placeholder="CPF/CNPJ"
              value={this.state.cpfCnpj}
              onChange={this.handleChange}
              onKeyPress={(ev) => {
                ev.target.value.replace(/[^0-9]/g, "").length === 11 &&
                  this.setState({
                    cpfCnpjMask: "99.999.999/9999-99",
                  });
              }}
              defaultValue={this.state.cpfCnpj}
              maskPlaceholder={null}
            />
          </InputContainer>
          {/* <InputContainer className="input-container">
            <Label>Observações</Label>
            <InputText
              onChange={(e) => this.handleObservationsInput(e)}
              placeholder="Informe as observações do pedido"
              value={this.state.observations}
            />
          </InputContainer> */}
        </InputsContainer>
        {this.state.categories.map((cat) => (
          <ContainerButton
            key={cat.id}
            isDisabled={this.props.edit}
            inactive={
              this.props.active && !this.props.edit
                ? this.props.active !== cat.id
                : false
            }
            className={this.props.edit ? `disabled` : ``}
            category={cat.id}
            onClick={() =>
              this.props.edit ? `` : this.props.handleCategoryClick(cat.id)
            }
          >
            {cat.name}
          </ContainerButton>
        ))}
      </Container>
    );
  }
}

export default NewOrderCategories;
