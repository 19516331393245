import React from "react";
import { AutoSizer, List } from "react-virtualized";
import _ from "lodash";

import Header from "../components/Header";
import Menu from "../components/Menu";
import { ColumnWrapper, Column } from "../components/Columns";
import {
  Container,
  ContainerButton,
  ContainerSection,
  ContainerSwitch,
} from "../components/Container";
import {
  ArrowTitle,
  Description,
  CategoryTitle,
} from "../components/Typography";
import { Button, ArrowConfirm } from "../components/Button";
import {
  InputContainer,
  InputText,
  Label,
  InputMoney,
} from "../components/InputText";
import Checkbox from "../components/Checkbox";
import Dropdown from "../components/Dropdown";
import ModalConfirmedIngredient from "../components/ModalConfirmedIngredient";
import ModalConfirmedCategory from "../components/ModalConfirmedCategory";
import ModalConfirmedProduct from "../components/ModalConfirmedProduct";
import ModalConfirmedCondiment from "../components/ModalConfirmedCondiment";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AlertModal from "../components/AlertModal";
import NumberFormat from "react-number-format";

import Axios from "../Axios";

class OrderItems extends React.Component {
  state = {
    shouldRenderStep2: false,
    shouldRenderStep3: false,
    shouldRenderStep4: false,
    isEditing: false,
    type: null,
    availableCategories: [],
    isSmallOptions: [
      { id: true, name: "Pequenas - (P)" },
      { id: false, name: "Grandes - (G)" },
    ],
    productCategory: null,
    ingredientCategory: null,
    addedProducts: [],
    productIngredients: "",
    confirmIngredient: false,
    categoryList: [],
    productList: [],
    ingredientList: [],
    condimentsList: [],
    condiment: null,
    confirmCondiment: false,
    condimentPrice: 0,
    isAlertModalOpened: false,
    alertModalTitle: "",
  };

  componentDidMount() {
	document.title = 'Itens do Cardápio - Frita Delivery'
  }

  resetSteps() {
    this.setState({
      shouldRenderStep2: false,
      shouldRenderStep3: false,
      shouldRenderStep4: false,
      type: null,
      addedIngredients: [],
      productIngredients: "",
      ingredientsList: [],
      ingredient: null,
      confirmCategory: false,
      confirmIngredient: false,
      confirmProduct: false,
      confirmCondiment: false,
      category: null,
      condiment: null,
      condimentsList: [],
      condimentPrice: 0,
    });
  }

  onClickAction(e) {
    let el = e.currentTarget;

    if (el.getAttribute("data-action") === "add") {
      this.setState({
        isEditing: false,
        shouldRenderStep2: true,
        shouldRenderStep3: false,
        shouldRenderStep4: false,
        productCategory: null,
        type: null,
      });

      return;
    }

    this.setState({
      isEditing: true,
      shouldRenderStep2: true,
      shouldRenderStep3: false,
      shouldRenderStep4: false,
      productCategory: null,
      type: null,
    });
  }

  onClickType(e) {
    let el = e.currentTarget,
      type = el.getAttribute("data-type");

    this.setState(
      {
        type,
        shouldRenderStep3: false,
        shouldRenderStep4: false,
        productCategory: null,
        ingredientsList: [],
        productList: [],
        categoryList: [],
        addedIngredients: [],
        condimentsList: [],
        category: null,
        ingredient: null,
        product: null,
        condiment: null,
      },
      () => {
        this.setState({ shouldRenderStep3: true });
      }
    );

    if (type === "product") {
      this.loadAvailableCategories();
    }

    if (type === "ingredient") {
      this.loadAvailableCategories();
    }

    if (this.state.isEditing && type === "ingredient") {
      this.loadIngredients();
    }

    if (this.state.isEditing && type === "product") {
      this.loadProducts();
    }

    if (this.state.isEditing && type === "category") {
      this.loadCategories();
    }

    if (this.state.isEditing && type === "condiments") {
      this.loadCondiments();
    }
  }

  onClickChooseProducts() {
    this.setState({ shouldRenderStep4: true });
    this.loadProducts();
  }

  onClickChooseIngredients() {
    this.setState({ shouldRenderStep4: true });
    this.loadIngredients(this.state.productCategory.id);
  }

  async loadProducts() {
    await Axios.get("/products").then((res) => {
      let products = res.data.rows;
      products = products.sort((a, b) => a.name.localeCompare(b.name));
      this.setState({ productList: products });
    });
  }

  async loadIngredients(categoryId = null) {
    const res = await Axios.get("/ingredients")

    if(categoryId) {
      this.setState({ ingredientsList: res.data.filter(ingredient => ingredient.category_id === categoryId) });
      return
    }

    this.setState({ ingredientsList: res.data });
  }

  async loadCategories() {
    await Axios.get("/categories").then((res) => {
      this.setState({ categoryList: res.data.rows });
    });
  }

  async loadCondiments() {
    await Axios.get("/spices").then((res) => {
      this.setState({ condimentsList: res.data.rows });
    });
  }

  async loadAvailableCategories() {
    await Axios.get("/categories").then((res) => {
      this.setState({ availableCategories: res.data.rows });
    });
  }

  saveIngredientsToProduct() {
    let temp = this.state.addedIngredients.map((p) => p.name).join(", ");

    this.setState({
      shouldRenderStep4: false,
      productIngredients: temp,
    });
  }

  addIngredientsToProduct(el) {
    let data = JSON.parse(el.getAttribute("data-value"));
    let temp = [...this.state.addedIngredients];

    if (el.checked) {
      temp.push(data);
    } else {
      temp = temp.filter((p) => p.id !== data.id);
    }

    this.setState({
      addedIngredients: temp,
    });
  }

  stringToNumber(str) {
    return parseFloat(str.replace(",", ".").replace("%", ""));
  }

  async saveCategory(e) {
    e.preventDefault();
    console.log(
      this.stringToNumber(this.form.elements.icms_base.value),
      this.stringToNumber(this.form.elements.icms.value)
    )
    if (
      this.stringToNumber(this.form.elements.icms_base.value) > 100 ||
      this.stringToNumber(this.form.elements.icms_base.value) < 0
    ) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle: "Os valores de ICMS devem estar entre 0 e 100",
      });
      return;
    }
    if (
      this.stringToNumber(this.form.elements.icms.value) > 100 ||
      this.stringToNumber(this.form.elements.icms.value) < 0
    ) {
      this.setState({
        isAlertModalOpened: true,
        alertModalTitle: "Os valores de ICMS devem estar entre 0 e 100",
      });
      return;
    }

    if (!this.state.confirmCategory) {
      this.setState({ confirmCategory: true });
      return;
    }

    let data = {
      name: this.form.elements.categoryName.value,
      icms_base: this.stringToNumber(this.form.elements.icms_base.value),
      icms: this.stringToNumber(this.form.elements.icms.value),
      icms_uf_destino: this.stringToNumber(
        this.form.elements.icms_uf_destino.value
      ),
      icms_uf_remetente: this.stringToNumber(
        this.form.elements.icms_uf_remetente.value
      ),
      cbnef: this.form.elements.cbnef.value,
      note_product_code: this.form.elements.note_product_code.value,
      note_ncm: this.form.elements.note_ncm.value,
      note_cest: this.form.elements.note_cest.value,
      note_cfop: this.form.elements.note_cfop.value,
    };

    for (let key of Object.keys(data)) if (!data[key] && data[key] !== 0) delete data[key];

    if (this.state.isEditing) {
      await Axios.put(`/categories/${this.state.category.id}`, data).then(
        (res) => {
          if (res.status === 201) {
            this.setState({ confirmCategory: false }, () => {
              this.setState({
                isAlertModalOpened: true,
                alertModalTitle: `Categoria ${data.name} editada com sucesso`,
              });
              this.resetSteps();
            });
          }
        }
      );
      return;
    }

    await Axios.post("/categories", data).then((res) => {
      if (res.status === 201) {
        this.setState({
          isAlertModalOpened: true,
          alertModalTitle: "Categoria cadastrada com sucesso",
        });
        this.resetSteps();
      }
    });
  }

  price(price) {
    if (typeof price == "string") {
      return price.replace("R$ ", "").replace(",", ".");
    }
    return price;
  }

  async saveIngredient(e, ingredient, price, quantity, category) {
    console.log(this.state.ingredient)
    e.preventDefault();
    if (!this.state.confirmIngredient) {
      this.setState({ confirmIngredient: true });
      return;
    }

    let data = {
      name: ingredient,
      price: this.price(price),
      showInPortions: false,
      quantity,
      category_id: category.id
    };

    if (this.state.isEditing) {
      await Axios.put(`/ingredients/${this.state.ingredient.id}`, data).then(
        (res) => {
          if (res.status === 200) {
            this.setState({ confirmIngredient: false }, () => {
              this.setState({
                isAlertModalOpened: true,
                alertModalTitle: `Ingrediente: ${data.name} editado com sucesso`,
              });
              this.resetSteps();
            });
          }
        }
      );
      return;
    }
    await Axios.post("/ingredients", data).then((res) => {
      if (res.status === 201) {
        this.setState({ confirmIngredient: false }, () => {
          this.setState({
            isAlertModalOpened: true,
            alertModalTitle: `Ingrediente cadastrado com sucesso`,
          });
          this.resetSteps();
        });
      }
    });
  }

  async saveProduct(e) {
    e.preventDefault();

    if (!this.state.confirmProduct) {
      this.setState({ confirmProduct: true });
      return;
    }

    let data = {
      category_id: this.state.productCategory.id,
      name: this.form.elements.productName.value,
      price: parseFloat(this.price(this.form.elements.productPrice.value)),
      express: this?.form?.elements?.express?.checked,
      isSpecialFries: this?.form?.elements?.isSpecialFries?.checked,
      isSmall: this.state.isSmall,
      quantity: 1,
      position: 1,
    };

    if (this.state.isEditing) {
      await Axios.put(`/products/${this.state.product.id}`, data).then(
        (res) => {
          if (res.status === 200) {
            let remove = _.difference(
              this.state.product.ingredients,
              this.state.addedIngredients
            );
            let add = _.difference(
              this.state.addedIngredients,
              this.state.product.ingredients
            );

            remove.map((i) =>
              this.removeIngredientFromProduct(this.state.product.id, i.id)
            );
            add.map((i) =>
              this.addIngredientToProduct(this.state.product.id, i.id)
            );

            this.setState({ confirmproduct: false }, () => {
              this.setState({
                isAlertModalOpened: true,
                alertModalTitle: `Produto ${data.name} editado com sucesso`,
              });
              this.resetSteps();
            });
          }
        }
      );
      return;
    }

    await Axios.post("/products", data).then((res) => {
      if (res.data.id) {
        this.saveIngredients(res.data.id);
      }
    });
  }

  async saveCondiment(e) {
    e.preventDefault();

    if (!this.state.confirmCondiment) {
      this.setState({ confirmCondiment: true });
      return;
    }

    let data = {
      name: this.form.elements.condimentName.value,
      price: parseFloat(this.price(this.form.elements.condimentPrice.value)),
      default: this.form.elements.isDefault.checked,
    };

    if (this.state.isEditing) {
      await Axios.put(`/spices/${this.state.condiment.id}`, data).then(
        (res) => {
          if (res.status === 201) {
            this.setState({ confirmCondiment: false }, () => {
              this.setState({
                isAlertModalOpened: true,
                alertModalTitle: `Condimento ${data.name} editado com sucesso`,
              });
              this.resetSteps();
            });
          }
        }
      );
      return;
    }

    await Axios.post("/spices", data).then((res) => {
      if (res.status === 201) {
        this.setState({ confirmCondiment: false }, () => {
          this.setState({
            isAlertModalOpened: true,
            alertModalTitle: `Condimento cadastrado com sucesso`,
          });
          this.resetSteps();
        });
      }
    });
  }

  async saveIngredients(product) {
    this.state.addedIngredients.map((ing) => {
      let data = {
        ingredient_id: ing.id,
      };

      Axios.post(`/products/${product}/ingredients`, data);

      return data;
    });
    this.resetSteps();
  }

  async addIngredientToProduct(product, ingredient) {
    let data = {
      ingredient_id: ingredient,
      quantity: 1,
    };

    await Axios.post(`/products/${product}/ingredients`, data);
  }

  async removeIngredientFromProduct(product, id) {
    await Axios.delete(`/products/${product}/ingredients/${id}`);
  }

  async deleteIngredient() {
    if (window.confirm("Deseja deletar esse ingrediente?"))
      await Axios.delete(`/ingredients/${this.state.ingredient.id}`).then(
        () => {
          this.resetSteps();
          this.setState({
            isAlertModalOpened: true,
            alertModalTitle: "Ingrediente deletado com sucesso!",
          });
        }
      );
  }

  async deleteCondiment() {
    if (window.confirm("Deseja deletar esse condimento?"))
      await Axios.delete(`/spices/${this.state.condiment.id}`).then(() => {
        this.resetSteps();
        this.setState({
          isAlertModalOpened: true,
          alertModalTitle: "Condimento deletado com sucesso!",
        });
      });
  }

  async deleteCategory() {
    if (window.confirm("Deseja deletar essa categoria?"))
      await Axios.delete(`/categories/${this.state.category.id}`).then(() => {
        this.resetSteps();
        this.setState({
          isAlertModalOpened: true,
          alertModalTitle: "Categoria deletada com sucesso!",
        });
      });
  }

  async deleteProduct() {
    if (window.confirm("Deseja deletar esse produto?"))
      await Axios.delete(`/products/${this.state.product.id}`).then(() => {
        this.resetSteps();
        this.setState({
          isAlertModalOpened: true,
          alertModalTitle: "Produto deletado com sucesso!",
        });
      });
  }
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  handleDragEnd = async (result) => {
    this.setState({
      ingredientsList: this.reorder(
        this.state.ingredientsList,
        result.source.index,
        result.destination.index
      ),
    });

    setTimeout(() => {
      const ingredientID =
        this.state.ingredientsList[result.destination.index].id;
      const position = result.destination.index;
      Axios.put(`/ingredients/${ingredientID}/position`, {
        position,
      });
    }, 1000);
  };

  formatPercentage(string) {
    return Number(string).toFixed(2).toString().replace(".", ",") + "%";
  }

  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <Menu />
        <main>
          <ColumnWrapper>
            <Column step="1">
              <Container>
                <ContainerButton
                  isDisabled={
                    this.state.shouldRenderStep2 && this.state.isEditing
                  }
                  onClick={this.onClickAction.bind(this)}
                  data-action="add"
                >
                  Cadastrar novo item
                </ContainerButton>

                <ContainerButton
                  isDisabled={
                    this.state.shouldRenderStep2 && !this.state.isEditing
                  }
                  onClick={this.onClickAction.bind(this)}
                  data-action="edit"
                >
                  Editar itens existentes
                </ContainerButton>
              </Container>
            </Column>

            {this.state.shouldRenderStep2 ? (
              <Column step="2">
                {this.state.isEditing ? (
                  <Container>
                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="product"
                      isDisabled={
                        this.state.type && this.state.type !== "product"
                      }
                    >
                      Editar Produto
                    </ContainerButton>

                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="ingredient"
                      isDisabled={
                        this.state.type && this.state.type !== "ingredient"
                      }
                    >
                      Editar Ingrediente
                    </ContainerButton>

                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="category"
                      isDisabled={
                        this.state.type && this.state.type !== "category"
                      }
                    >
                      Editar Categoria
                    </ContainerButton>

                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="condiments"
                      isDisabled={
                        this.state.type && this.state.type !== "condiments"
                      }
                    >
                      Editar Condimentos
                    </ContainerButton>
                  </Container>
                ) : (
                  <Container>
                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="product"
                      isDisabled={
                        this.state.type && this.state.type !== "product"
                      }
                    >
                      Novo Produto
                    </ContainerButton>

                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="ingredient"
                      isDisabled={
                        this.state.type && this.state.type !== "ingredient"
                      }
                    >
                      Novo Ingrediente
                    </ContainerButton>

                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="category"
                      isDisabled={
                        this.state.type && this.state.type !== "category"
                      }
                    >
                      Nova Categoria
                    </ContainerButton>

                    <ContainerButton
                      onClick={this.onClickType.bind(this)}
                      data-type="condiments"
                      isDisabled={
                        this.state.type && this.state.type !== "condiments"
                      }
                    >
                      Novo Condimento
                    </ContainerButton>
                  </Container>
                )}
              </Column>
            ) : (
              ``
            )}

            <Column step="3" style={{ width: "27.5%" }}>
              {this.state.shouldRenderStep3 && !this.state.isEditing ? (
                this.state.type === "category" ? (
                  <Container hasPadding={true}>
                    <form
                      onSubmit={this.saveCategory.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer>
                          <Label>Nome da categoria</Label>
                          <InputText
                            required={true}
                            ref={(input) => (this.categoryName = input)}
                            noMargin={true}
                            name="categoryName"
                            placeholder="Digite um nome para categoria"
                          />
                        </InputContainer>
                        <div
                          style={{
                            width: "100%",
                            height: "2px",
                            backgroundColor: "#CCCCCC",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        ></div>
                        <InputContainer>
                          <Label>Código do produto</Label>
                          <InputText
                            required={true}
                            name="note_product_code"
                            placeholder={"IJ34FSD"}
                          />
                        </InputContainer>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Base de ICMS</Label>
                            <NumberFormat
                              required={true}
                              name="icms_base"
                              placeholder="00,00%"
                              customInput={InputText}
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>ICMS</Label>
                            <NumberFormat
                              name="icms"
                              required={true}
                              customInput={InputText}
                              suffix="%"
                              placeholder="00,00%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>ICMS - UF/Destino</Label>
                            <NumberFormat
                              required={true}
                              name="icms_uf_destino"
                              placeholder={"00,00%"}
                              customInput={InputText}
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>ICMS - UF/Remetente</Label>
                            <NumberFormat
                              name="icms_uf_remetente"
                              required={true}
                              customInput={InputText}
                              suffix="%"
                              placeholder={"00,00%"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>CBNEF</Label>
                            <InputText
                              maxLength="8"
                              name="cbnef"
                              placeholder={"UF000000"}
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Código CEST</Label>
                            <InputText
                              maxLength="9"
                              name="note_cest"
                              placeholder={"00.000.00"}
                            />
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Código NCM</Label>
                            <InputText
                              maxLength="8"
                              name="note_ncm"
                              placeholder={"00000000"}
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Código CFOP</Label>
                            <InputText
                              maxLength="4"
                              name="note_cfop"
                              placeholder={"0000"}
                            />
                          </InputContainer>
                        </div>
                      </ContainerSection>
                      {/* <ContainerSection hasDivider={true}>
                                                <ArrowTitle
                                                    isClickable={true}
                                                    title="Escolher Produtos"
                                                    onClick={this.onClickChooseProducts.bind(this)}/>
                                                <Description lineClamp={2}>
                                                    {
                                                        this.state.categoryProducts.length === 0 ?
                                                            `Nenhum produto selecionado`
                                                            : `${this.state.categoryProducts}`
                                                    }
                                                </Description>
                                            </ContainerSection> */}
                      <ContainerSection>
                        <Button type="submit">ADICIONAR CATEGORIA</Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  ``
                )
              ) : (
                ``
              )}

              {this.state.shouldRenderStep3 &&
              this.state.isEditing &&
              this.state.type === "category" ? (
                this.state.category ? (
                  <Container hasPadding={true}>
                    <header>
                      <ContainerSwitch noBorder style={{ padding: 0 }}>
                        <ArrowConfirm
                          title="Voltar"
                          style={{ paddingBottom: 18 }}
                          onClick={() => this.setState({ category: null })}
                        />
                      </ContainerSwitch>
                    </header>
                    <form
                      onSubmit={this.saveCategory.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer>
                          <Label>Nome da categoria</Label>
                          <InputText
                            required={true}
                            noMargin={true}
                            defaultValue={this.state.category.name}
                            name="categoryName"
                            placeholder="Digite um nome para categoria"
                          />
                        </InputContainer>
                        <div
                          style={{
                            width: "100%",
                            height: "2px",
                            backgroundColor: "#CCCCCC",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        ></div>
                        <InputContainer>
                          <Label>Código do produto</Label>
                          <InputText
                            required={true}
                            name="note_product_code"
                            placeholder={"IJ34FSD"}
                            defaultValue={
                              this.state.category.note_product_code &&
                              this.formatPercentage(
                                this.state.category.note_product_code
                              )
                            }
                          />
                        </InputContainer>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Base de ICMS</Label>
                            <NumberFormat
                              required={true}
                              name="icms_base"
                              placeholder={"00,00%"}
                              defaultValue={
                                this.state.category.icms_base &&
                                this.formatPercentage(
                                  this.state.category.icms_base
                                )
                              }
                              customInput={InputText}
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>ICMS</Label>
                            <NumberFormat
                              name="icms"
                              required={true}
                              customInput={InputText}
                              suffix="%"
                              placeholder={"00,00%"}
                              defaultValue={
                                this.state.category.icms_base &&
                                this.formatPercentage(this.state.category.icms)
                              }
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>ICMS - UF/Destino</Label>
                            <NumberFormat
                              required={true}
                              name="icms_uf_destino"
                              placeholder={"00,00%"}
                              defaultValue={
                                this.state.category.icms_uf_destino &&
                                this.formatPercentage(
                                  this.state.category.icms_uf_destino
                                )
                              }
                              customInput={InputText}
                              suffix="%"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>ICMS - UF/Remetente</Label>
                            <NumberFormat
                              name="icms_uf_remetente"
                              required={true}
                              customInput={InputText}
                              suffix="%"
                              placeholder={"00,00%"}
                              defaultValue={
                                this.state.category.icms_uf_remetente &&
                                this.formatPercentage(
                                  this.state.category.icms_uf_remetente
                                )
                              }
                              decimalScale={2}
                              fixedDecimalScale={true}
                              decimalSeparator=","
                            />
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>CBNEF</Label>
                            <InputText
                              maxLength="8"
                              name="cbnef"
                              placeholder={"UF000000"}
                              defaultValue={this.state.category.cbnef}
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Código CEST</Label>
                            <InputText
                              maxLength="9"
                              name="note_cest"
                              placeholder={"00.000.00"}
                              defaultValue={this.state.category.note_cest}
                            />
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Código NCM</Label>
                            <InputText
                              maxLength="8"
                              name="note_ncm"
                              placeholder={"00000000"}
                              defaultValue={this.state.category.note_ncm}
                            />
                          </InputContainer>
                          <InputContainer style={{ width: "49%" }}>
                            <Label>Código CFOP</Label>
                            <InputText
                              maxLength="4"
                              name="note_cfop"
                              placeholder={"0000"}
                              defaultValue={this.state.category.note_cfop}
                            />
                          </InputContainer>
                        </div>
                      </ContainerSection>
                      <ContainerSection>
                        <Button
                          style={{
                            backgroundColor: "#e8222d",
                            border: "none",
                            color: "#fff",
                          }}
                          transparent={true}
                          type="button"
                          onClick={this.deleteCategory.bind(this)}
                        >
                          EXCLUIR CATEGORIA
                        </Button>
                        <Button margin="12px 0" type="submit">
                          SALVAR CATEGORIA
                        </Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  <Container hasPadding={false} style={{ height: "100%" }}>
                    <AutoSizer style={{ outline: 0 }}>
                      {({ width, height }) => {
                        return (
                          <List
                            height={height}
                            width={width}
                            rowHeight={72}
                            rowCount={this.state.categoryList.length}
                            className="category-list"
                            rowRenderer={() => {
                              return this.state.categoryList.map((category) => (
                                <ContainerButton
                                  key={category.id}
                                  onClick={() => this.setState({ category })}
                                  category={category}
                                >
                                  {category.name}
                                </ContainerButton>
                              ));
                            }}
                          ></List>
                        );
                      }}
                    </AutoSizer>
                  </Container>
                )
              ) : (
                ``
              )}

              {this.state.shouldRenderStep3 && !this.state.isEditing ? (
                this.state.type === "condiments" ? (
                  <Container hasPadding={true}>
                    <form
                      onSubmit={this.saveCondiment.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer>
                          <Label>Nome do codimento</Label>
                          <InputText
                            required={true}
                            ref={(input) => (this.condimentName = input)}
                            noMargin={true}
                            name="condimentName"
                            placeholder="Digite um nome para o condimento"
                          />
                        </InputContainer>
                        <InputContainer>
                          <Label>Preço do codimento</Label>
                          <InputMoney
                            required
                            precision={2}
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator=""
                            name="condimentPrice"
                            placeholder="Digite o preço"
                            onChangeEvent={(e, val) => {
                              let condimentPrice = this.state.condimentPrice;
                              condimentPrice = val;
                              this.setState({ condimentPrice });
                            }}
                            value={this.state.condimentPrice}
                          ></InputMoney>
                        </InputContainer>
                        <InputContainer style={{ marginTop: 20 }}>
                          <Checkbox
                            defaultCheck={false}
                            inputName="isDefault"
                            name="Condimento padrão"
                            id="isDefault"
                          ></Checkbox>
                        </InputContainer>
                      </ContainerSection>
                      <ContainerSection>
                        <Button type="submit">ADICIONAR CONDIMENTO</Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  ``
                )
              ) : (
                ``
              )}
              {this.state.shouldRenderStep3 &&
              this.state.isEditing &&
              this.state.type === "condiments" ? (
                this.state.condiment ? (
                  <Container hasPadding={true}>
                    <header>
                      <ContainerSwitch noBorder style={{ padding: 0 }}>
                        <ArrowConfirm
                          title="Voltar"
                          style={{ paddingBottom: 18 }}
                          onClick={() => this.setState({ condiment: null })}
                        />
                      </ContainerSwitch>
                    </header>
                    <form
                      onSubmit={this.saveCondiment.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer>
                          <Label>Nome do condimento</Label>
                          <InputText
                            required={true}
                            noMargin={true}
                            defaultValue={this.state.condiment.name}
                            name="condimentName"
                            placeholder="Digite um nome para o condimento"
                          />
                        </InputContainer>
                        <InputContainer>
                          <Label>Preço</Label>
                          <InputMoney
                            required
                            precision={2}
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator=""
                            name="condimentPrice"
                            placeholder="Digite o preço"
                            onChangeEvent={(e, val) => {
                              let condiment = this.state.condiment;
                              condiment.price = val;
                              this.setState({ condiment });
                            }}
                            value={this.state.condiment.price}
                          ></InputMoney>
                        </InputContainer>
                        <InputContainer style={{ marginTop: 20 }}>
                          <Checkbox
                            defaultChecked={this.state.condiment.default}
                            inputName="isDefault"
                            name="Condimento padrão"
                            id="isDefault"
                          ></Checkbox>
                        </InputContainer>
                      </ContainerSection>
                      <ContainerSection>
                        <Button
                          transparent={true}
                          type="button"
                          onClick={this.deleteCondiment.bind(this)}
                        >
                          EXCLUIR CONDIMENTO
                        </Button>
                        <Button margin="12px 0" type="submit">
                          SALVAR CONDIMENTO
                        </Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  <Container hasPadding={false} style={{ height: "100%" }}>
                    <AutoSizer style={{ outline: 0 }}>
                      {({ width, height }) => {
                        return (
                          <List
                            height={height}
                            width={width}
                            rowHeight={72}
                            rowCount={this.state.condimentsList.length}
                            className="condiment-list"
                            rowRenderer={() => {
                              return this.state.condimentsList.map(
                                (condiment) => (
                                  <ContainerButton
                                    key={condiment.category_id}
                                    onClick={() => this.setState({ condiment })}
                                    condiment={condiment}
                                  >
                                    {condiment.name}
                                  </ContainerButton>
                                )
                              );
                            }}
                          ></List>
                        );
                      }}
                    </AutoSizer>
                  </Container>
                )
              ) : (
                ``
              )}

              {this.state.shouldRenderStep3 && !this.state.isEditing ? (
                this.state.type === "ingredient" ? (
                  <Container hasPadding={true}>
                    <form
                      onSubmit={this.saveIngredient.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer style={{ marginBottom: "24px" }}>
                          <Label>Nome do ingrediente</Label>
                          <InputText
                            required
                            noMargin={true}
                            name="ingredientName"
                            placeholder="Digite um nome para o ingrediente"
                          />
                        </InputContainer>
                        <InputContainer style={{ marginBottom: "24px" }}>
                          <Label>Preço</Label>
                          <InputMoney
                            required
                            precision={2}
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator=""
                            name="ingredientPrice"
                            placeholder="Digite o preço"
                            onChangeEvent={(e, val) => {
                              let ingredient = this.state.ingredient
                                ? this.state.ingredient
                                : {};
                              ingredient.price = val;
                              this.setState({ ingredient });
                            }}
                            value={
                              this.state.ingredient
                                ? this.state.ingredient.price
                                : 0
                            }
                          ></InputMoney>
                        </InputContainer>
                        <InputContainer style={{ marginBottom: "24px" }}>
                          <Label>Quantidade em estoque:</Label>
                          <InputText
                            required
                            thousandSeparator=""
                            name="quantity"
                            placeholder="Digite a quantidade"
                          ></InputText>
                        </InputContainer>
                        <InputContainer>
                          <Label>Categoria</Label>
                          <Dropdown
                            name="ingredientCategory"
                            dropdownGap={-5}
                            labelField="name"
                            valueField="id"
                            placeholder="Selecione uma categoria"
                            options={this.state.availableCategories}
                            onChange={(values) =>
                              this.setState({ ingredientCategory: values[0] })
                            }
                          />
                        </InputContainer>

                        <Description style={{ marginBottom: 12 }}>
                          O preço aparece caso o ingrediente seja colocado como
                          opcional para algum produto.
                        </Description>

                        
                        
                      </ContainerSection>
                      {/* <ContainerSection hasDivider={true}>
                                                <ArrowTitle
                                                    isClickable={true}
                                                    title="Disponibilizar como opcional"
                                                    onClick={this.onClickChooseProducts.bind(this)}/>
                                                <Description>Nenhum ingrediente selecionado</Description>
                                            </ContainerSection> */}
                      <ContainerSection>
                        <Button type="submit">ADICIONAR INGREDIENTE</Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  ``
                )
              ) : (
                ``
              )}

              {this.state.shouldRenderStep3 &&
              this.state.isEditing &&
              this.state.type === "ingredient" ? (
                this.state.ingredient ? (
                  <Container hasPadding={true}>
                    <header>
                      <ContainerSwitch noBorder style={{ padding: 0 }}>
                        <ArrowConfirm
                          title="Voltar"
                          style={{ paddingBottom: 18 }}
                          onClick={() => this.setState({ ingredient: null })}
                        />
                      </ContainerSwitch>
                    </header>
                    <form
                      onSubmit={this.saveIngredient.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer style={{ marginBottom: "24px" }}>
                          <Label>Nome do ingrediente</Label>
                          <InputText
                            required
                            ref={(input) => {
                              this.ingredientName = input;
                            }}
                            noMargin={true}
                            name="ingredientName"
                            defaultValue={this.state.ingredient.name}
                            placeholder="Digite um nome para o ingrediente"
                          />
                        </InputContainer>
                        <InputContainer style={{ marginBottom: "24px" }}>
                          <Label>Preço</Label>
                          <InputMoney
                            required
                            precision={2}
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator=""
                            name="ingredientPrice"
                            placeholder="Digite o preço"
                            onChangeEvent={(e, val) => {
                              let ingredient = this.state.ingredient;
                              ingredient.price = val;
                              this.setState({ ingredient });
                            }}
                            value={this.state.ingredient.price}
                          ></InputMoney>
                        </InputContainer>
                        <InputContainer style={{ marginBottom: "24px" }}>
                          <Label>Categoria</Label>
                          <Dropdown
                            name="ingredientCategory"
                            dropdownGap={-5}
                            labelField="name"
                            valueField="id"
                            placeholder="Selecione uma categoria"
                            options={this.state.availableCategories}
                            values={[
                              this.state.availableCategories.find(category => {
                                return this.state.ingredient.category_id === category.id
                              })
                            ]}
                            onChange={(values) =>
                              this.setState({ ingredientCategory: values[0] })
                            }
                          />
                        </InputContainer>
                        <Description style={{ marginBottom: 12 }}>
                          O preço aparece caso o ingrediente seja colocado como
                          opcional para algum produto.
                        </Description>
                      </ContainerSection>
                      {/* <ContainerSection hasDivider={true}>
                                                <ArrowTitle
                                                    isClickable={true}
                                                    title="Disponibilizar como opcional"
                                                    onClick={this.onClickChooseProducts.bind(this)}/>
                                                <Description>Nenhum ingrediente selecionado</Description>
                                            </ContainerSection> */}
                      <ContainerSection>
                        <Button
                          transparent={true}
                          type="button"
                          onClick={this.deleteIngredient.bind(this)}
                        >
                          EXCLUIR INGREDIENTE
                        </Button>
                        <Button margin="12px 0" type="submit">
                          SALVAR INGREDIENTE
                        </Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  <Container hasPadding={false} style={{ height: "100%" }}>
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                      <Droppable droppableId="ingredientsDroppable">
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}>
                            {this.state.ingredientsList.map(
                              (ingredient, index) => (
                                <Draggable
                                  draggableId={index.toString()}
                                  key={index}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <ContainerButton
                                      key={ingredient.id}
                                      onClick={() =>
                                        this.setState({ ingredient })
                                      }
                                      ingredient={ingredient}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {ingredient.name}
                                    </ContainerButton>
                                  )}
                                </Draggable>
                              )
                            )}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Container>
                )
              ) : (
                ``
              )}

              {this.state.shouldRenderStep3 && !this.state.isEditing ? (
                this.state.type === "product" ? (
                  <Container hasPadding={true} style={{ overflow: "visible" }}>
                    {!this.state.productCategory ? (
                      <ContainerSection>
                        <Description>
                          Selecione a qual categoria este produto vai pertencer
                        </Description>
                      </ContainerSection>
                    ) : (
                      ``
                    )}
                    <form
                      onSubmit={this.saveProduct.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection style={{ paddingTop: 5 }}>
                        <CategoryTitle style={{ marginBottom: 8 }}>
                          Categoria
                        </CategoryTitle>
                        <Dropdown
                          name="product-category"
                          dropdownGap={-5}
                          labelField="name"
                          valueField="id"
                          placeholder="Selecione uma categoria"
                          options={this.state.availableCategories}
                          onChange={(values) => {
                            this.loadIngredients(values[0].id)
                            this.setState({ productCategory: values[0] })
                          }}
                        />
                      </ContainerSection>

                      {this.state.productCategory ? (
                        <React.Fragment>
                          <ContainerSection noPaddingTop={true}>
                            <InputContainer>
                              <Label>Nome</Label>
                              <InputText
                                noMargin={true}
                                name="productName"
                                placeholder="Digite um nome para o produto"
                              />
                            </InputContainer>
                          </ContainerSection>

                          <ContainerSection
                            hasDivider={true}
                            noPaddingTop={true}
                          >
                            <InputContainer>
                              <Label>Preço</Label>
                              <InputMoney
                                required
                                precision={2}
                                prefix="R$ "
                                decimalSeparator=","
                                thousandSeparator=""
                                name="productPrice"
                                placeholder="Digite o preço"
                                onChangeEvent={(e, val) => {
                                  let productPrice = this.state.productPrice;
                                  productPrice = val;
                                  this.setState({ productPrice });
                                }}
                                value={this.state.productPrice}
                              ></InputMoney>
                            </InputContainer>
                            {/* {(this.state.productCategory?.name.toLowerCase() ===
                              "porções" ||
                              this.state.productCategory?.name.toLowerCase() ===
                                "lanches") && (
                              <InputContainer
                                style={{ marginTop: 20, marginBottom: 12 }}
                              >
                                <Checkbox
                                  defaultChecked={false}
                                  id="express"
                                  name="Pedido Expresso"
                                  inputName="express"
                                />
                              </InputContainer>
                            )} */}
                            {this.state.productCategory?.name.toLowerCase() ===
                              "porções" && (
                              <>
                                <InputContainer
                                  style={{ marginTop: 20, marginBottom: 12 }}
                                >
                                  <Checkbox
                                    defaultChecked={false}
                                    id="isSpecialFries"
                                    name="Fritas Especiais"
                                    inputName="isSpecialFries"
                                    onChange={(e) => {
                                      let product = this.state.product
                                        ? this.state.product
                                        : {};
                                      product.isSpecialFries = e.target.checked;
                                      this.setState({ product });
                                    }}
                                  />
                                </InputContainer>
                                {this.state?.product?.isSpecialFries && (
                                  <InputContainer
                                    style={{ marginTop: 20, marginBottom: 12 }}
                                  >
                                    <Dropdown
                                      name="isSmall"
                                      dropdownGap={-5}
                                      labelField="name"
                                      valueField="id"
                                      placeholder="Selecione uma opção"
                                      options={this.state.isSmallOptions}
                                      onChange={(values) =>
                                        this.setState({ isSmall: values[0].id })
                                      }
                                    />
                                  </InputContainer>
                                )}
                              </>
                            )}
                          </ContainerSection>

                          <ContainerSection hasDivider={true}>
                            <ArrowTitle
                              isClickable={true}
                              title="Ingredientes"
                              onClick={this.onClickChooseIngredients.bind(this)}
                            />
                            <Description lineClamp={2}>
                              {this.state.productIngredients.length === 0
                                ? `Nenhum ingrediente selecionado`
                                : `${this.state.productIngredients}`}
                            </Description>
                          </ContainerSection>

                          {/* <ContainerSection hasDivider={true}>
                                                            <ArrowTitle
                                                                isClickable={true}
                                                                title="Opcionais"
                                                                onClick={this.onClickChooseProducts.bind(this)}/>
                                                            <Description>Clique para Selecionar</Description>
                                                        </ContainerSection> */}

                          <ContainerSection>
                            <Button type="submit">ADICIONAR PRODUTO</Button>
                          </ContainerSection>
                        </React.Fragment>
                      ) : (
                        ``
                      )}
                    </form>
                  </Container>
                ) : (
                  ``
                )
              ) : (
                ``
              )}

              {this.state.shouldRenderStep3 &&
              this.state.isEditing &&
              this.state.type === "product" ? (
                this.state.product ? (
                  <Container hasPadding={true}>
                    <header>
                      <ContainerSwitch noBorder style={{ padding: 0 }}>
                        <ArrowConfirm
                          title="Voltar"
                          style={{ paddingBottom: 18 }}
                          onClick={() => this.setState({ product: null })}
                        />
                      </ContainerSwitch>
                    </header>
                    <form
                      onSubmit={this.saveProduct.bind(this)}
                      ref={(form) => (this.form = form)}
                    >
                      <ContainerSection style={{ paddingTop: 5 }}>
                        <CategoryTitle style={{ marginBottom: 8 }}>
                          Categoria
                        </CategoryTitle>
                        <Dropdown
                          name="productCategory"
                          dropdownGap={-5}
                          labelField="name"
                          valueField="id"
                          placeholder="Selecione uma categoria"
                          values={[this.state.product.category]}
                          options={this.state.availableCategories}
                          onChange={(values) => {
                            let product = this.state.product;
                            product.category = values[0];
                            
                            this.loadIngredients(values[0].id)

                            this.setState({
                              productCategory: values[0],
                              product,
                            });
                          }}
                        />
                      </ContainerSection>
                      <ContainerSection noPaddingTop={true}>
                        <InputContainer>
                          <Label>Nome</Label>
                          <InputText
                            defaultValue={this.state.product.name}
                            noMargin={true}
                            name="productName"
                            placeholder="Digite um nome para o produto"
                          />
                        </InputContainer>
                      </ContainerSection>

                      <ContainerSection hasDivider={true} noPaddingTop={true}>
                        <InputContainer>
                          <Label>Preço</Label>
                          <InputMoney
                            required
                            precision={2}
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator=""
                            name="productPrice"
                            placeholder="Digite o preço"
                            onChangeEvent={(e, val) => {
                              let product = this.state.product;
                              product.price = val;
                              this.setState({ product });
                            }}
                            value={this.state.product.price}
                          ></InputMoney>
                        </InputContainer>

                        {/* {(this.state.productCategory?.name.toLowerCase() ===
                          "porções" ||
                          this.state.productCategory?.name.toLowerCase() ===
                            "lanches") && (
                          <InputContainer
                            style={{ marginTop: 20, marginBottom: 12 }}
                          >
                            <Checkbox
                              defaultChecked={this.state.product.express}
                              id="express"
                              name="Pedido Expresso"
                              inputName="express"
                            />
                          </InputContainer>
                        )} */}
                        {this.state.productCategory?.name.toLowerCase() ===
                          "porções" && (
                          <>
                            <InputContainer
                              style={{ marginTop: 20, marginBottom: 12 }}
                            >
                              <Checkbox
                                defaultChecked={
                                  this.state.product.isSpecialFries
                                }
                                id="isSpecialFries"
                                name="Fritas Especiais"
                                inputName="isSpecialFries"
                                onChange={(e) => {
                                  let product = this.state.product
                                    ? this.state.product
                                    : {};
                                  product.isSpecialFries = e.target.checked;
                                  this.setState({ product });
                                }}
                              />
                            </InputContainer>
                            {this.state?.product.isSpecialFries && (
                              <InputContainer
                                style={{ marginTop: 20, marginBottom: 12 }}
                              >
                                <Dropdown
                                  name="isSmall"
                                  dropdownGap={-5}
                                  labelField="name"
                                  valueField="id"
                                  placeholder="Selecione uma opção"
                                  values={
                                    this.state?.product.isSmall !== null
                                      ? [
                                          this.state?.isSmallOptions.find(
                                            (o) =>
                                              o.id ===
                                              this.state.product.isSmall
                                          ),
                                        ]
                                      : []
                                  }
                                  options={this.state.isSmallOptions}
                                  onChange={(values) =>
                                    this.setState({ isSmall: values[0].id })
                                  }
                                />
                              </InputContainer>
                            )}
                          </>
                        )}
                      </ContainerSection>

                      <ContainerSection hasDivider={true}>
                        <ArrowTitle
                          isClickable={true}
                          title="Ingredientes"
                          onClick={this.onClickChooseIngredients.bind(this)}
                        />
                        <Description lineClamp={2}>
                          {this.state.productIngredients.length === 0
                            ? `Nenhum ingrediente selecionado`
                            : `${this.state.productIngredients}`}
                        </Description>
                      </ContainerSection>
                      <ContainerSection>
                        <Button
                          transparent={true}
                          type="button"
                          onClick={this.deleteProduct.bind(this)}
                        >
                          EXCLUIR PRODUTO
                        </Button>
                        <Button margin="12px 0" type="submit">
                          SALVAR PRODUTO
                        </Button>
                      </ContainerSection>
                    </form>
                  </Container>
                ) : (
                  <Container hasPadding={false} style={{ height: "100%" }}>
                    <AutoSizer style={{ outline: 0 }}>
                      {({ width, height }) => {
                        return (
                          <List
                            height={height}
                            width={width}
                            rowHeight={72}
                            rowCount={this.state.productList.length}
                            className="product-list"
                            rowRenderer={() => {
                              return this.state.productList.map((product) => (
                                <ContainerButton
                                  key={product.id}
                                  onClick={() => {
                                    this.setState({
                                      productCategory: product.category,
                                      product,
                                      productIngredients: product.ingredients
                                        .map((p) => p.name)
                                        .join(", "),
                                      addedIngredients: product.ingredients,
                                    });
                                  }}
                                  product={product}
                                >
                                  {product.name}
                                </ContainerButton>
                              ));
                            }}
                          ></List>
                        );
                      }}
                    </AutoSizer>
                  </Container>
                )
              ) : (
                ``
              )}
            </Column>

            <Column step="4" style={{ width: "22.5%" }}>
              {this.state.shouldRenderStep4 ? (
                <Container style={{ marginLeft: "-25px" }} step="4" full={true}>
                  {this.state.type === "product" ? (
                    this.state.ingredientsList.length === 0 ? (
                      <ContainerSwitch noBorder>
                        <CategoryTitle style={{ paddingLeft: 24 }}>
                          Carregando...
                        </CategoryTitle>
                      </ContainerSwitch>
                    ) : (
                      <section style={{ height: "100%" }}>
                        <header>
                          <ContainerSwitch
                            noBorder
                            style={{ paddingBottom: 12 }}
                          >
                            <ArrowConfirm
                              title="Confirmar ingredientes"
                              style={{ paddingBottom: 18 }}
                              onClick={this.saveIngredientsToProduct.bind(this)}
                            />
                            <Description
                              style={{
                                fontSize: 16,
                                lineHeight: "24px",
                                paddingBottom: 18,
                              }}
                            >
                              Selecione todos os ingredientes que irão aparecer
                              por padrão no produto
                            </Description>
                          </ContainerSwitch>
                        </header>
                        <AutoSizer>
                          {({ width, height }) => (
                            <List
                              width={width}
                              height={height - 135}
                              rowHeight={66}
                              rowCount={this.state.ingredientsList.length}
                              rowRenderer={({ key, index, style, parent }) => {
                                const ingredient =
                                  this.state.ingredientsList[index];

                                return (
                                  <div key={key} style={style}>
                                    <ContainerSwitch key={ingredient.id}>
                                      <Checkbox
                                        defaultChecked={
                                          this.state.productIngredients.length >
                                          0
                                            ? this.state.addedIngredients.filter(
                                                (p) => ingredient.id === p.id
                                              ).length > 0
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.addIngredientsToProduct(
                                            e.currentTarget
                                          )
                                        }
                                        dataValue={JSON.stringify({
                                          id: ingredient.id,
                                          name: ingredient.name,
                                        })}
                                        id={ingredient.id}
                                        name={ingredient.name}
                                      ></Checkbox>
                                    </ContainerSwitch>
                                  </div>
                                );
                              }}
                            />
                          )}
                        </AutoSizer>
                      </section>
                    )
                  ) : (
                    ``
                  )}
                </Container>
              ) : (
                ``
              )}
            </Column>
            {this.state.confirmIngredient ? (
              <ModalConfirmedIngredient
                ingredient={this.form.elements.ingredientName.value}
                quantity={this.form?.elements?.quantity?.value}
                category={this.state.ingredientCategory}
                price={
                  this.state.ingredient?.price ? this.state.ingredient?.price : 0
                }
                portions={this.state.ingredient?.showInPortions}
                close={() => this.setState({ confirmIngredient: false })}
                save={this.saveIngredient.bind(this)}
              ></ModalConfirmedIngredient>
            ) : (
              ``
            )}

            {this.state.confirmCategory ? (
              <ModalConfirmedCategory
                category={this.form.elements.categoryName.value}
                close={() => this.setState({ confirmCategory: false })}
                save={this.saveCategory.bind(this)}
              ></ModalConfirmedCategory>
            ) : (
              ``
            )}

            {this.state.confirmProduct ? (
              <ModalConfirmedProduct
                category={this.state.productCategory}
                product={this.form.elements.productName.value}
                price={this.form.elements.productPrice.value}
                ingredients={this.state.productIngredients}
                close={() => this.setState({ confirmProduct: false })}
                save={this.saveProduct.bind(this)}
              ></ModalConfirmedProduct>
            ) : (
              ``
            )}

            {this.state.confirmCondiment ? (
              <ModalConfirmedCondiment
                name={this.form.elements.condimentName.value}
                price={this.form.elements.condimentPrice.value}
                close={() => this.setState({ confirmCondiment: false })}
                save={this.saveCondiment.bind(this)}
              ></ModalConfirmedCondiment>
            ) : (
              ``
            )}
          </ColumnWrapper>
          {this.state.isAlertModalOpened && (
            <div>
              <AlertModal
                title={this.state.alertModalTitle}
                onClose={() => {
                  this.setState({ isAlertModalOpened: false });
                }}
              />
            </div>
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default OrderItems;
